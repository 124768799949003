import { Component, Input, OnInit } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { environment } from '../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ShowLangSelector } from '../../../modules/ui/views/langSelector/store/langSelector.actions';
import { SyncLocker } from '../../../modules/ui/store/ui.actions';
import { ScreenSaverService } from '../../services/screenSaver.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements OnInit {
  logoHMY = 'assets/images/hmyWhite.png';
  logoHMYBlue = 'assets/images/mainLogo.png';
  // logoHMYBlue = 'assets/images/logoYudigar.png';
  // logoHMYBlue = 'assets/images/Logo-Leroy.png';
  txtHMYBlue = 'assets/images/txtHMYBlue.png';
  logoHome = 'assets/icons/home-icon.png';
  logoProfil = 'assets/icons/user-icon2.png';
  logoutImg = 'assets/images/logout.png';
  activeLang;
  showES;
  showFR;
  showEN;
  onlyStaff = null;
  showVideo = null;
  languages: string[];
  activeLangIcon;
  @Input() showNav = false;
  @Input() showProfil = false;
  @Input() navTitle = '';
  showProfilPopup = false;
  locker: Observable<any>;
  touchtime = 0;
  counttouch = 0;
  lockerId: string;
  isOnStaff = false;
  // tslint:disable-next-line: no-empty
  constructor(public store: Store, private translateService: TranslateService, private screenSaverService: ScreenSaverService, private router: Router) {
    if (this.router.url.indexOf('staff') !== -1 && this.router.url.indexOf('login') === -1) {
      this.isOnStaff = true;
    }
    this.locker = this.store.select(state => state.ui.locker);
    if (localStorage.getItem('lockerid')) {
      this.lockerId = localStorage.getItem('lockerid');
      this.store.dispatch(new SyncLocker());
    }
    this.locker.subscribe(lock => {
      if (lock && lock.languages) {
        this.languages = lock.languages;
        this.showES = this.languages.includes('es');
        this.showEN = this.languages.includes('en');
        this.showFR = this.languages.includes('fr');
        const lang = this.languages[0];
        localStorage.setItem('lang', lang);
        this.translateService.use(lang);
      }
    });
  }

  // tslint:disable-next-line: no-empty
  ngOnInit() {
    this.screenSaverService.isOn$.subscribe(bool => {
      if ( !bool ) {
        this.activeLang = this.translateService.currentLang;
      }
    });
    this.activeLang = this.translateService.currentLang;
  }

  goHome() {
    this.store.dispatch(new Navigate(['home']));
  }
  popupLogin() {
    setTimeout(() => {
      this.showProfilPopup = true;
    }, 200);
  }
  hidePopupLogin() {
    this.showProfilPopup = false;
  }
  goLogin() {
    this.store.dispatch(new Navigate(['cc', 'staff']));
  }

  changeLang (lang: string) {
    this.translateService.use(lang);
    this.activeLang = lang;
  }

  goToStaff () {
    console.log({navTitle: this.navTitle});
    if (this.navTitle === 'MENU' || this.navTitle === 'Consigna') {
      if (this.touchtime === 0 || this.counttouch === 0) {
          this.touchtime = new Date().getTime();
      } else if ((new Date().getTime() - this.touchtime < 600) && (this.counttouch === 2)) {
          this.touchtime = 0;
          this.counttouch = -1;
          this.accessStaff();
      } else if ((new Date().getTime() - this.touchtime < 600) && (this.counttouch < 2)) {
          this.touchtime = new Date().getTime();
      } else if ((new Date().getTime() - this.touchtime > 600)) {
          this.touchtime = 0;
          this.counttouch = 0;
          this.goToStaff();
      }
      this.counttouch++;
    }
  }

  accessStaff() {
    localStorage.removeItem(environment.staffStorageTokenKey.consigna);
    localStorage.removeItem(environment.staffStorageTokenKey.cc);
    this.screenSaverService.staffLoggedIn$.next(false);
    this.store.dispatch(new Navigate(['cc', 'staff', 'login'], {}));
  }

  langMenu () {
    this.store.dispatch(new ShowLangSelector());
  }

  logout() {
    this.store.dispatch(new Navigate(['screensaver']));
  }
}
