import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-staff-buttons',
    templateUrl: './staff-buttons.component.html',
    styleUrls: ['./staff-buttons.component.scss']
  })

export class StaffButtonsComponent implements OnInit {
    @Input() forMobile = true;
    _buttons = [];
    @Input()
    get buttons() {
        return this._buttons;
    }
    set buttons(v) {
        this._buttons = v;
        this._buttons = this._buttons.filter(x => {
            console.log({class: x.class, click: x.click, forMobile: this.forMobile});
            x.class = `${x.class} ${((this.forMobile && (x.click !== 'goToConsigment' && x.click !== 'goToCC')) || (!this.forMobile && (x.click === 'goToConsigment' || x.click === 'goToCC'))) ? 'for-mobile' : 'nfor-mobile'}`;
            x.forMobile = this.forMobile;
            return x;
        });
    }
    @Output() goToEvent: EventEmitter<string> = new EventEmitter<string>();
    ngOnInit(): void {
        console.log('cc menu buttons init');
    }
    goTo(nameEvent: string) {
        this.goToEvent.emit(nameEvent);
    }
}
