import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { HideHelpConsignaConfirmation } from './store/helpConsigna.actions';

@Component({
  selector: 'app-help-consigna-modal',
  templateUrl: './helpConsigna.component.html',
  styleUrls: ['./helpConsigna.component.scss']
})
export class HelpConsignaComponent implements OnInit {

  isHidden = true;
  area = '';
  @Select(state => state.helpconsignadropoff.modalData) data$: Observable<any[]>;

  constructor(public store: Store) {
    this.data$.pipe().subscribe( (data: any) => {
      this.isHidden = data.hidden;
      this.area = data.area;
    });
  }

  ngOnInit() {
    //
  }

  acceptButton () {
    this.store.dispatch(new HideHelpConsignaConfirmation());
  }

}
