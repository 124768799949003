import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HideLoadingSpinner, ShowLoadingSpinner } from '../components/loading-spinner/store/loading-spinner.actions';

@Injectable({ providedIn: 'root' })
export class LoadingSpinnerInterceptor implements HttpInterceptor {
  constructor(private store: Store) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.store.dispatch(new ShowLoadingSpinner());
    return next.handle(req).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.store.dispatch(new HideLoadingSpinner());
          }
        },
        (_err: any) => {
          // tslint:disable-next-line:no-identical-functions
          this.store.dispatch(new HideLoadingSpinner());
        },
      ),
    );
  }
}
