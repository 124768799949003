import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../../common/shared.module';
import { CodeComponent } from './views/pickup/code/code.component';
import { AfterScanComponent } from './views/pickup/afterScan/afterScan.component';
import { ManualCodeComponent } from './views/pickup/manualCode/manualCode.component';



@NgModule({
  declarations: [ CodeComponent, ManualCodeComponent, AfterScanComponent],
  imports: [
    SharedModule,
    RouterModule.forChild([
      {
        path: '',
        component: CodeComponent,
        data: { firstInit: true }
      },
      {
        path: 'menu',
        component: CodeComponent,
        data: { firstInit: false }
      },
      {
        path: 'homeCC',
        component: CodeComponent,
      },
      {
        path: 'manual-code',
        component: ManualCodeComponent,
      },
      {
        path: 'scan-response',
        component: AfterScanComponent,
      },
    ]),
  ]
})
export class CustomerModule { }
