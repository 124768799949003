import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterContactData'
})
export class FilterContactDataPipe implements PipeTransform {

  transform(value: any, arg: any, arg2: any): any {
    if (arg === '') {
        return value;
    }
    /*  let text1;
    let text2;
    let text3;
    if ( arg2 === 'email' && (arg.indexOf('@') !== -1) ) {
      text1 = text2 = text3 = `${arg.substring(0, (arg.indexOf('@') - 2))}**${arg.substring(arg.indexOf('@'))}`;
    } else if (arg2 === 'email' && (arg.indexOf('@') === -1)) {
      text1 = arg.toLowerCase();
      text2 = `${arg.substring(0, arg.length-1).toLowerCase()}*`;
      text3 = `${arg.substring(0, arg.length-2).toLowerCase()}**`;
    } else if (arg2 === 'sms' && arg.length > 4) {
      text1 = text2 = text3 = `${arg.substring(0, 4)}**${arg.substring(6)}`;
    }
    else if (arg2 === 'sms' && arg.length <= 4) {
      text1 = text2 = text3 = arg;
    }

    const resultPosts = [];
    for (const post of value) {
      if ((post.phone.toLowerCase().indexOf(text1) > -1) || (post.phone.toLowerCase().indexOf(text2) > -1) || (post.phone.toLowerCase().indexOf(text3) > -1)) {
        resultPosts.push(post);
      }
    } */

    const resultPosts = [];
    for (const post of value) {
      if (((arg2 === 'sms' || arg2 === 'email') && (post.realContact.toLowerCase() === (arg.toLowerCase()))) || (arg2 === 'orderId' && (post.orderId.toLowerCase().indexOf(arg) > -1))) {
        resultPosts.push(post);
      }
    }
    return resultPosts;
  }
}
