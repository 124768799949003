export const environment = {
  production: true,
  /*   apiUrl: 'http://localhost:3000/api/v1',
  publicAssetsUrl: 'http://localhost:3000', */
  apiUrl: 'http://localhost:5010/api/v1',
  publicAssetsUrl: 'http://localhost:5700',
  DEFAULT_TIMER: 45 * 1000,
  version: 'v1.0.0',
  showQR: false,
  staffStorageTokenKey: { cc: 'token-cc', consigna: 'token-consigna' },
};
