import { Action, State, StateContext } from '@ngxs/store';
import { HideLoadingSpinner, ShowLoadingSpinner } from './loading-spinner.actions';

export class LoadingSpinnerStateModel {
  hidden: boolean;
}

export const LoadingSpinnerStateDefaults: LoadingSpinnerStateModel = {
  hidden: true,
};

@State<LoadingSpinnerStateModel>({
  name: 'loadingSpinner',
  defaults: LoadingSpinnerStateDefaults,
})
export class LoadingSpinnerrState {

  showOrNot = false;

  @Action(ShowLoadingSpinner)
  show(ctx: StateContext<LoadingSpinnerStateModel>) {
    this.showOrNot = true;

    setTimeout( () => {
      if ( this.showOrNot ) {
        ctx.patchState({
          hidden: false,
        });
      }
    }, 300);
  }

  @Action(HideLoadingSpinner)
  hide(ctx: StateContext<LoadingSpinnerStateModel>) {
      /* setTimeout( () => {
        this.showOrNot = false;
      ctx.patchState({
        hidden: true,
      });
      }, 10000); */
      this.showOrNot = false;
      ctx.patchState({
        hidden: true,
      });
  }
}
