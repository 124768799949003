import { Action, State, StateContext } from '@ngxs/store';
import { HideLangSelector, ShowLangSelector } from './langSelector.actions';

export class LangSelectorStateModel {
  hidden: boolean;
}

export const LangSelectorStateDefaults: LangSelectorStateModel = {
  hidden: true,
};

@State<LangSelectorStateModel>({
  name: 'langSelector',
  defaults: LangSelectorStateDefaults,
})
export class LangSelectorState {
  @Action(ShowLangSelector)
  show(ctx: StateContext<LangSelectorStateModel>) {
    ctx.patchState({
      hidden: false,
    });
  }

  @Action(HideLangSelector)
  hide(ctx: StateContext<LangSelectorStateModel>) {
    ctx.patchState({
      hidden: true,
    });
  }
}
