import { OrderToBoxDto } from '../dto/orderToBox.dto';
import { StaffLoginDto } from '../dto/staffLogin.dto';


/* export class LoginCC {
  static readonly type = '[CCSTAFF] LoginCC';
  constructor(public loginDto: LoginDto) {}
} */

export class RetireLockers {
  static readonly type = '[CCSTAFF] RetireLockers';
  constructor(public email: string, public code: string, public reserve_date: Date, public idLockers: string[]) {}
}

export class OpenLockers {
  static readonly type = '[CCSTAFF] OpenLockers';
  constructor(public idLockers: string[]) {}
}

export class GetCCData {
  static readonly type = '[CCSTAFF] GetCCData';
}

export class MakeDeliveryToBox {
  static readonly type = '[CCSTAFF] MakeDeliveryToBox';
  constructor(public order: OrderToBoxDto) {}
}

export class MakeDeliveryToLocker {
  static readonly type = '[CCSTAFF] MakeDeliveryToLocker';
  constructor(public code: any) {}
}

export class MakeDeliveryToLockerFirstStep {
  static readonly type = '[CCSTAFF] MakeDeliveryToLockerFirstStep';
  constructor(public code: any) {}
}

export class BoxToCustomer {
  static readonly type = '[CCSTAFF] BoxToCustomer';
  constructor(public orderId: string) {}
}

export class StaffLogin {
  static readonly type = '[CCSTAFF] StaffLogin';
  constructor(public staffMember: StaffLoginDto) {}
}

export class RetireExpiredOrder {
  static readonly type = '[CCSTAFF] RetireExpiredOrder';
  constructor(public dto: { orderId: string}) {}
}

export class OpenLockersByStaff {
  static readonly type = '[CCSTAFF] OpenLockersByStaff';
  constructor(public orderId: string) {}
}

export class OpenLockersByCoords {
  static readonly type = '[CCSTAFF] OpenLockersByCoords';
  constructor(public x: string, public y: string) {}
}

export class DisableLock {
  static readonly type = '[CCSTAFF] DisableLock';
  constructor(public lockerId: string, public disable: boolean) {}
}

export class InsertFakeData {
  static readonly type = '[CCSTAFF] InsertFakeData';
}

export class GetConfig {
  static readonly type = '[CCSTAFF] GetConfig';
}

export class FromLockersToLocker0 {
  static readonly type = '[CCSTAFF] FromLockersToLocker0';
  constructor(public dto: { orderId: string }) {}
}
