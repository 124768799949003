import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { delay } from 'rxjs/operators';
import { HideLangSelector } from './store/langSelector.actions';

@Component({
  selector: 'app-lang-selector',
  templateUrl: './langSelector.component.html',
  styleUrls: ['./langSelector.component.scss']
})
export class LangSelectorComponent {

  isHidden = true;
  @Select(state => state.langSelector.hidden) hidden$: Observable<boolean>;

  constructor(public store: Store, private translateService: TranslateService) {
    this.hidden$.pipe(delay(200)).subscribe(hidden => {
      this.isHidden = hidden;
    });
  }

  changeLang (lang: string) {
    this.translateService.use(lang);
    this.store.dispatch(new HideLangSelector());
  }
}
