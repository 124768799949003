import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ClientToLockersDto } from './dto/clientToLockers.dto';
import { PickUpCodeDto } from './dto/pickupCodeDto';

@Injectable({ providedIn: 'root' })
export class CustomerConsignaService {
  private options: any;
  constructor(private http: HttpClient) {
    this.options = {
      headers: {
        Authorization: '1234'
      }
    };
  }

  getMueble (): Observable<any> {
    const lockerId = localStorage.getItem('lockerid');
    return this.http.post(`${environment.apiUrl}/cabinet/get-data`, {lockerId}, this.options);
  }

  getMuebleConsigna (): Observable<any> {
    const lockerId = localStorage.getItem('lockerid');
    return this.http.post(`${environment.apiUrl}/cabinet/get-data`, {lockerId}, this.options);
  }

  getCosignaData (): Observable<any> {
    const lockerId = localStorage.getItem('lockerid');
    return this.http.post(`${environment.apiUrl}/consignment-customer/data`, {lockerId}, this.options);
  }

  clientToLocker ( data: ClientToLockersDto): Observable<any> {
    const lockerId = localStorage.getItem('lockerid');
    return this.http.post(`${environment.apiUrl}/consignment-customer/dropoff`, { ...data, lockerId }, this.options);
  }

  pickupFromLocker ( data: PickUpCodeDto): Observable<any> {
    const lockerId = localStorage.getItem('lockerid');
    return this.http.post(`${environment.apiUrl}/consignment-customer/pickup-code`, { ...data, lockerId }, this.options);
  }

  dropoffConfirmation ( data: {id: string, code: string}): Observable<any> {
    const lockerId = localStorage.getItem('lockerid');
    return this.http.post(`${environment.apiUrl}/consignment-customer/dropoff-confirmation`, { ...data, lockerId }, this.options);
  }
}
