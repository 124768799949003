import { Component, HostListener, OnInit } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store, Select } from '@ngxs/store';
// tslint:disable-next-line:import-name
import { ShowAlert } from '../../../../../../common/components/alert/store/alert.actions';
import { Observable } from 'rxjs';
import { ShowHelpCCConfirmation } from '../../helpCC/store/helpCC.actions';
import { OrderCodeScanned } from '../../../store/pickup.actions';
import { ScreenSaverService } from '../../../../../../common/services/screenSaver.service';

@Component({
  selector: 'app-code',
  templateUrl: './code.component.html',
  styleUrls: ['./code.component.scss']
})
export class CodeComponent implements OnInit {
  @Select(state => state.ui.locker) mueble$: Observable<any>;
  code = '';
  stringFromScanner = '';
  QRSephora = 'assets/images/qr-ayto-zar-red.png';
  onlyCC = true;
  regexExp = /^[\w&.\-]+$/;
  onlyStaff = null;
  showCustomerSection = null;
  onlyStaffSub = null;
  showContent = false;
  isOnErrorSub = null;
  constructor(public store: Store, private screenSaverService: ScreenSaverService) {}

  ngOnInit() {
    this.mueble$.subscribe( mueble => {
      if (mueble) {

        this.onlyStaff = mueble.onlyStaff;
        this.showCustomerSection = mueble.showCustomerSection;
        if (this.onlyStaff !== null && this.onlyStaff && this.showCustomerSection === false) {
          this.store.dispatch(new Navigate(['screensaver']));
          this.onlyStaffSub.unsubscribe();
        } else if (this.onlyStaff != null) {
          this.showContent = true;
        }

        const business = mueble.business_types;
        if (  business.length !== 0 ) {
          this.onlyCC = false;
        }

      }
    });
    this.isOnErrorSub = this.screenSaverService.isError$.subscribe(error => {
      if (error != null) {
        this.showContent = true;
        this.isOnErrorSub.unsubscribe();
      }
    });
  }

  keyPress(event: any) {
    if (event && event.target && event.target.value) {
    this.code = String(event.target.value);
    }
}

  goToManualCode() {
      this.store.dispatch(new Navigate(['cc', 'pickup', 'manual-code']));
  }

  onShowScreenSaver() {
    this.code = '';
    this.stringFromScanner = '';
    document.getElementById('codeInput').blur();
  }

  onHideScreenSaver() {
    document.getElementById('codeInput').focus();
  }

  @HostListener('document:keyup', ['$event'])
  barcodeScan(ev: KeyboardEvent) {
    // console.log('SCANNING....', ev.key);
    if (ev && ev.key && ev.key.toString() === 'Enter' && this.stringFromScanner  && this.stringFromScanner.length > 0) {
      // console.log('SEND SCANNER INPUT -> ', this.stringFromScanner);
      this.triggerCode(this.stringFromScanner);
      this.stringFromScanner = '';
    } else if (ev && ev.key && this.regexExp.test(ev.key)) {
      // console.log('WRITING....', ev.key);
      this.stringFromScanner += ev.key;
    }
  }

  triggerCode(code: string) {
    if (code) {
      this.store.dispatch( new OrderCodeScanned(code) ).toPromise().then( data => {
        const resp = data && data.ccPickup && data.ccPickup.orderCodeScannedData ? data.ccPickup.orderCodeScannedData : data ;
        if (resp && resp.success && !resp.isBox ) {
          this.store.dispatch(
            new Navigate(['cc', 'pickup', 'scan-response'], { responseType: 'OK' })
          );
        } else if (resp.success && resp.isBox ) {
          this.store.dispatch(new Navigate(['cc', 'pickup', 'scan-response'], {responseType: 'NOTHERE'}));
        }
      }).catch(
        err => {
          this.store.dispatch(new ShowAlert({ type: 'error', text: 'MESSAGES.CODE_NOT_OK' }));
        }
      );
    }
  }

  goHome() {
    this.store.dispatch(
      new Navigate([''])
    );
  }

  showHelp () {
    this.store.dispatch(new ShowHelpCCConfirmation({area: 'pickup'}));
  }
}
