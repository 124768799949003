import { Action, State, StateContext } from '@ngxs/store';
import { ShowConsignaDropoffConfirmation, HideConsignaDropoffConfirmation } from './confirmConsignaDropoff.actions';

export class ConfirmationStateModel {
  modalData: {
    hidden: boolean;
    type: string;
    phone: string;
    numberLockers: string;
    numberColdLockers: string;
  };
}

export const ConfirmationStateDefaults: ConfirmationStateModel = {
  modalData: {
    hidden: true,
    type: '',
    phone: '',
    numberLockers: '0',
    numberColdLockers: '0'
  }
};

@State<ConfirmationStateModel>({
  name: 'confirmationdropoff',
  defaults: ConfirmationStateDefaults,
})
export class ConfirmConsignaDropoffState {
  @Action(ShowConsignaDropoffConfirmation)
  show(ctx: StateContext<ConfirmationStateModel>, {payload}: any) {
    ctx.patchState({
      modalData: {
        type: payload.type,
        hidden: false,
        phone: payload.phone,
        numberLockers: payload.numberLockers,
        numberColdLockers: payload.numberColdLockers
      }
    });
  }

  @Action(HideConsignaDropoffConfirmation)
  hide(ctx: StateContext<ConfirmationStateModel>) {
    ctx.patchState({
      modalData: {
      hidden: true,
      type: '',
      phone: '',
      numberLockers: '0',
      numberColdLockers: '0'
      }
    });
  }

}


