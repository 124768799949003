import { LoginDto } from '../../../../common/model/login.dto';
import { StaffLoginDto } from '../dto/staffLogin.dto';

export class LoginConsigna {
  static readonly type = '[CONSIGNASTAFF] LoginConsigna';
  constructor(public loginDto: LoginDto) {}
}

export class GetConsignaMueble {
  static readonly type = '[CONSIGNASTAFF] GetConsignaMueble';
}

export class GetConsignaData {
  static readonly type = '[CONSIGNASTAFF] GetConsignaData';
}

export class StaffConsignaLogin {
  static readonly type = '[CONSIGNASTAFF] StaffLogin';
  constructor(public staffMember: StaffLoginDto) {}
}

export class OpenByOrder {
  static readonly type = '[CONSIGNASTAFF] OpenByOrder';
  constructor(public code: string ) {}
}

export class OpenLockersByCoordsConsigna {
  static readonly type = '[CONSIGNASTAFF] OpenLockersByCoords';
  constructor(public x: string, public y: string) {}
}

export class GetConsignaConfig {
  static readonly type = '[CONSIGNASTAFF] GetConsignaConfig';
}

export class RetireOrder {
  static readonly type = '[CONSIGNASTAFF] RetireOrder';
  constructor(public code: string ) {}
}

export class SendNotification {
  static readonly type = '[CONSIGNASTAFF] SendNotification';
  constructor(public code: string ) {}
}

export class DisableLock {
  static readonly type = '[CONSIGNASTAFF] DisableLock';
  constructor(public lockerId: string, public disable: boolean) {}
}

export class FromLockersToLocker0 {
  static readonly type = '[CONSIGNASTAFF] FromLockersToLocker0';
  constructor(public code: string ) {}
}

export class MakeDeliveryToLocker {
  static readonly type = '[CONSIGNASTAFF] MakeDeliveryToLocker';
  constructor(public code: any) {}
}

export class MakeDeliveryToLockerFirstStep {
  static readonly type = '[CONSIGNASTAFF] MakeDeliveryToLockerFirstStep';
  constructor(public code: any) {}
}
