import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { ScreenSaverService } from '../../services/screenSaver.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-out-of-service',
  templateUrl: './outOfService.component.html',
  styleUrls: ['./outOfService.component.scss'],
})
export class OutOfServiceComponent implements OnInit, OnDestroy {

  @Select(state => state.outOfService.modalData) data$: Observable<any[]>;

  outOfServiceTimeoutFn = null;

  outOfServiceTimeout = 2 * 60 * 1000;

  clientStaff;

  isOn: any = false;

  show = false;

  homeRoute: string[] = [''];

  constructor(public store: Store, private screenSaverService: ScreenSaverService) {}

  ngOnInit() {
    this.initOutOfServiceCheck();
    this.screenSaverService.isOn$.subscribe(bool => {
      this.isOn = bool;
    });
  }

  ngOnDestroy() {
    clearTimeout(this.outOfServiceTimeoutFn);
  }

  initOutOfServiceCheck() {
    // this.outOfServiceTimeoutFn();
  }
}
