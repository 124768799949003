import { Injectable, OnInit } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ScreenSaverService implements OnInit {

  pause$ = new Subject<boolean>();

  staffLoggedIn$ = new Subject<boolean>();

  isOn$ = new Subject<boolean>();

  forceLaunch$ = new Subject<boolean>();

  isError$ = new Subject<any>();

  ngOnInit () {
    this.pause$.next(false);
    this.isOn$.next(false);
    this.staffLoggedIn$.next(false);
    this.forceLaunch$.next(false);
    this.isError$.next(null);
  }
}
