import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { HideLegalTextDropoffConfirmation } from './store/legalTextConsignaDropoff.actions';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-legal-text-modal',
  templateUrl: './legalText.component.html',
  styleUrls: ['./legalText.component.scss']
})
export class LegalTextComponent implements OnInit {

  isHidden = true;
  @Select(state => state.legaltextdropoff.modalData) data$: Observable<any[]>;

  constructor(public store: Store, public translate: TranslateService) {
    this.data$.pipe().subscribe( (data: any) => {
      this.isHidden = data.hidden;
    });
  }

  ngOnInit() {
    //
  }

  acceptButton () {
    this.store.dispatch(new HideLegalTextDropoffConfirmation());
  }

}
