import { FormBuilder, FormGroup } from '@angular/forms';

export class FormbuilderComponent<T> {
  formGroup: FormGroup = null;
  get formValue(): T {
    return this.formGroup.value as T;
  }

  constructor(public fb: FormBuilder) {}

  public createForm(model: T): void {
    this.formGroup = this.fb.group(model);
  }

  public updateForm(model: Partial<T>): void {
    this.formGroup.patchValue(model);
  }
}
