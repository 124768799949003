export class OrderOperation {
  static readonly type = '[PICKUP] OrderOperation';
  constructor(public code) {}
}

export class ClientLogout {
  static readonly type = '[PICKUP] ClientLogout';
}

export class QRCodePickup {
  static readonly type = '[PICKUP] QRCodePickup';
  constructor(public code) {}
}

export class VerifyCode {
  static readonly type = '[PICKUP] VerifyCode';
  constructor(public code) {}
}

export class VerifyCodeEmail {
  static readonly type = '[PICKUP] VerifyCodeEmail';
  constructor(public code, public email) {}
}

export class OrderCodeScanned {
  static readonly type = '[PICKUP] OrderCodeScanned';
  constructor(public code) {}
}

export class OrderCodeIntroduced {
  static readonly type = '[PICKUP] OrderCodeIntroduced';
  constructor(public code) {}
}

export class PickupOrderLogin {
  static readonly type = '[PICKUP] PickupOrderLogin';
  constructor(public orderId, public email) {}
}

export class ManualCodePickup {
  static readonly type = '[PICKUP] ManualCodePickup';
  constructor(public code, public email) {}
}
