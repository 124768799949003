import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { SocketService } from '../../common/services/socket.service';
import { environment } from '../../../environments/environment';
import { ValidateToken } from './store/ui.actions';

@Injectable({ providedIn: 'root' })
export class UiService {
  constructor(private http: HttpClient, private store: Store, private _socket: SocketService) {
    setTimeout(() => {
      this.validateDate();
    }, 500);
    setInterval(() => {
      this.validateDate();
    }, 60000);
  }
  syncMueble (): Observable<any> {
    const lockerId = localStorage.getItem('lockerid');
    return this.http.post(`${environment.apiUrl}/cabinet/get-data`, {lockerId});
  }
  getMueble (): Observable<any> {
    const lockerId = localStorage.getItem('lockerid');
    return this.http.post(`${environment.apiUrl}/cabinet/get-data`, {lockerId});
  }
  validateToken(token: string): Observable<any> {
    console.log('qrcode');
    return this.http.post(`${environment.platformUrl}/qrcode/validate/${token}`, {});
  }
  validateDate() {
    const tokenval = localStorage.getItem('tokenval');
    if (tokenval) {
      const tokendate = moment(localStorage.getItem('tokendate')).toDate();
      if (tokendate) {
        const todaydate = new Date();
        const minutes = this.dateDifference(tokendate, todaydate);
        if (minutes > 8) {
          this.store.dispatch(new Navigate(['screensaver']));
        }
      }
    }
  }
  dateDifference(date1, date2) {
    const diffTime = Math.abs(date2 - date1);
    return Math.ceil(diffTime / (1000 * 60));
  }
  processToken(token: string) {
    this.store.dispatch( new ValidateToken(token) ).toPromise().then( data => {
      const resp = data && data.ui.validateToken ? data.ui.validateToken : null;
      if (resp) {
        localStorage.setItem('lockerid', resp.lockerId);
        localStorage.setItem('tokenval', resp.valid);
        localStorage.setItem('tokenid', token);
        localStorage.setItem('socketTime', '0');
        localStorage.setItem('tokendate', moment().format('YYYY-MM-DD HH:mm:ss'));
        this._socket.sendMessage('changeQrCode', {lockerid: resp.lockerId});
        this.store.dispatch(
          new Navigate([''], undefined, {replaceUrl: true})
        );
      } else {
        if (resp.lockerId) {
          this._socket.sendMessage('reactiveQrCode', {lockerid: resp.lockerId});
        }
        this.store.dispatch(new Navigate(['screensaver']));
      }
    }).catch(err => {
      const lockerid = localStorage.getItem('lockerid');
      if (lockerid) {
        this._socket.sendMessage('changeQrCode', {lockerid});
      }
      this.store.dispatch(new Navigate(['screensaver']));
    });
  }

}
