import { Action, State, StateContext } from '@ngxs/store';
import { HideLegalTextDropoffConfirmation, ShowLegalTextDropoffConfirmation } from './legalTextConsignaDropoff.actions';

export class LegalTextStateModel {
  modalData: {
    hidden: boolean;
  };
}

export const LegalTextStateDefaults: LegalTextStateModel = {
  modalData: {
    hidden: true,

  }
};

@State<LegalTextStateModel>({
  name: 'legaltextdropoff',
  defaults: LegalTextStateDefaults,
})
export class LegalTextConsignaDropoffState {
  @Action(ShowLegalTextDropoffConfirmation)
  show(ctx: StateContext<LegalTextStateModel>) {
    ctx.patchState({
      modalData: {
        hidden: false,
      }
    });
  }

  @Action(HideLegalTextDropoffConfirmation)
  hide(ctx: StateContext<LegalTextStateModel>) {
    ctx.patchState({
      modalData: {
      hidden: true,
      }
    });
  }

}


