import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { ShowAlert } from '../components/alert/store/alert.actions';
import { ScreenSaverService } from '../services/screenSaver.service';

@Injectable()
export class GlobalHttpInterceptorService implements HttpInterceptor {
    constructor(public router: Router, public store: Store, private screenSaverService: ScreenSaverService) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error) => {
                if ( error.name === 'HttpErrorResponse' && error.status !== 404) {
                    this.screenSaverService.isError$.next(true);
                    this.store.dispatch(new ShowAlert({ type: 'error', text: 'GENERAL.CONNECTION_ERROR' }));
                }
            return throwError(error);
        })
        );
    }
}
