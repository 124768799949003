import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoudComponent } from './common/components/not-foud/not-foud.component';

const routes: Routes = [
  { path: '', loadChildren: () => import('./modules/ui/ui.module').then(m => m.UIModule) },
  { path: '404', component: NotFoudComponent},
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
