import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { ShowHelpCCConfirmation } from '../../helpCC/store/helpCC.actions';



@Component({
  selector: 'app-code',
  templateUrl: './afterScan.component.html',
  styleUrls: ['./afterScan.component.scss'],
})
export class AfterScanComponent implements OnInit {


  constructor(
  private route: ActivatedRoute,
  private store: Store
  ) {
  }

  responseText: string;
  responseType: string;

  responseAux = 0;

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params['responseType'] === 'OK') {
        this.responseText = 'MESSAGES.RESPONSE_OK';
        this.responseType = 'OK';
        this.responseAux = 1;
      }

      if (params['responseType'] === 'NOT') {
        this.responseText = 'MESSAGES.RESPONSE_NOT';
        this.responseType = 'NOT';
        this.responseAux = 2;
      }

      if (params['responseType'] === 'NOTHERE') {
        this.responseText = 'MESSAGES.RESPONSE_NOTHERE';
        this.responseType = 'NOTHERE';
        this.responseAux = 3;
      }
    });
  }


  goToScanner() {
    this.store.dispatch(new Navigate(['cc/pickup/menu']));
  }

  showHelp () {
    this.store.dispatch(new ShowHelpCCConfirmation({area: 'pickup'}));
  }
}
