import { Component, OnInit } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { LogOut } from '../../../modules/cc/staff/store/authCC.actions';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.html',
  styleUrls: ['./user-profile.scss'],
})
export class UserProfileComponent implements OnInit {
  optionsVisible = false;
  constructor(public store: Store) {}

  // tslint:disable-next-line: no-empty
  ngOnInit() {}

  onGoToHome() {
    this.store.dispatch(new Navigate(['home']));
  }
  showOptions() {
    this.optionsVisible = !this.optionsVisible;
  }

  logout() {
    this.store.dispatch(new LogOut());
  }
  openProfil() {
   // this.store.dispatch(new Navigate(['home', 'profil']));
  }
}
