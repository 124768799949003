import { Action, State, StateContext } from '@ngxs/store';
import { catchError, tap } from 'rxjs/operators';
import { StaffConsignaService } from '../staff.service';
import { LoginConsigna, GetConsignaMueble, GetConsignaData, StaffConsignaLogin, OpenByOrder, OpenLockersByCoordsConsigna, GetConsignaConfig, RetireOrder, SendNotification, DisableLock, FromLockersToLocker0, MakeDeliveryToLocker, MakeDeliveryToLockerFirstStep } from './consignaStaff.actions';
import { throwError } from 'rxjs';

export class ConsignaStaffStateModel {
  loginData: any;
  consignaMuebleData: any;
  consignaData: any;
  staffLoginData: any;
  openByOrderData: any;
  openLockersByCoordsConsignaData: any;
  consignaConfigData: any;
  sendNotificationData: any;
  retireOrderData: any;
  disableLockData: any;
  fromLockersToLocker0Data: any;
  makeDeliveryToLockerFirstStepData: any;
  makeDeliveryToLockerData: any;
}
export const ConsignaStaffStateDefaults: ConsignaStaffStateModel = {
  loginData: null,
  consignaMuebleData: null,
  consignaData: null,
  staffLoginData: null,
  openByOrderData: null,
  openLockersByCoordsConsignaData: null,
  consignaConfigData: null,
  sendNotificationData: null,
  retireOrderData: null,
  disableLockData: null,
  fromLockersToLocker0Data: null,
  makeDeliveryToLockerFirstStepData: null,
  makeDeliveryToLockerData: null,
};

@State<ConsignaStaffStateModel>({
  name: 'consignaStaffApi',
  defaults: ConsignaStaffStateDefaults,
})
export class ConsignaStaffApiState {
  constructor(
    private StaffService: StaffConsignaService,
    ) {}

  @Action(LoginConsigna)
  login(ctx: StateContext<ConsignaStaffStateModel>, {loginDto}) {
    return this.StaffService.login(loginDto).pipe(
      tap((result: any) => {
        ctx.patchState({
          loginData: result,
        });
      }),
      catchError( (err: any ) => {
        ctx.patchState({
          loginData: new Error(err),
        });
        return throwError(err);
      }));
  }

  @Action(GetConsignaMueble)
  getConsignaMueble(ctx: StateContext<ConsignaStaffStateModel>) {
    return this.StaffService.getMueble().pipe(
      tap((result: any) => {
        ctx.patchState({
          consignaMuebleData: result,
        });
      }),
      catchError( (err: any ) => {
        ctx.patchState({
          consignaMuebleData: new Error(err),
        });
        return throwError(err);
      }));
  }

  @Action(GetConsignaData)
  getConsignaData(ctx: StateContext<ConsignaStaffStateModel>) {
    return this.StaffService.getConsignaData().pipe(
      tap((result: any) => {
        ctx.patchState({
          consignaData: result,
        });
      }),
      catchError( (err: any ) => {
        ctx.patchState({
          consignaData: new Error(err),
        });
        return throwError(err);
      }));
  }

  @Action(StaffConsignaLogin)
  staffLogin(ctx: StateContext<ConsignaStaffStateModel>, {staffMember}) {
    return this.StaffService.staffLogin(staffMember).pipe(
      tap((result: any) => {
        ctx.patchState({
          staffLoginData: result,
        });
      }),
      catchError( (err: any ) => {
        ctx.patchState({
          staffLoginData: new Error(err),
        });
        return throwError(err);
      }));
  }

  @Action(OpenByOrder)
  openByOrder(ctx: StateContext<ConsignaStaffStateModel>, {code}) {
    return this.StaffService.openByOrder(code).pipe(
      tap((result: any) => {
        ctx.patchState({
          openByOrderData: result,
        });
      }),
      catchError( (err: any ) => {
        ctx.patchState({
          openByOrderData: new Error(err),
        });
        return throwError(err);
      }));
  }

  @Action(OpenLockersByCoordsConsigna)
  openLockersByCoordsConsigna(ctx: StateContext<ConsignaStaffStateModel>, {x, y}) {
    return this.StaffService.openLockersByCoords( x, y).pipe(
      tap((result: any) => {
        ctx.patchState({
          openLockersByCoordsConsignaData: result,
        });
      }),
      catchError( (err: any ) => {
        ctx.patchState({
          openLockersByCoordsConsignaData: new Error(err),
        });
        return throwError(err);
      }));
  }

  @Action(GetConsignaConfig)
  getConsignaConfig(ctx: StateContext<ConsignaStaffStateModel>, {x, y}) {
    // tslint:disable-next-line:no-identical-functions
    return this.StaffService.getConfig().pipe(
      tap((result: any) => {
        ctx.patchState({
          consignaConfigData: result,
        });
      }),
      catchError( (err: any ) => {
        ctx.patchState({
          consignaConfigData: new Error(err),
        });
        return throwError(err);
      }));
  }

  @Action(SendNotification)
  sendNotification(ctx: StateContext<ConsignaStaffStateModel>, {code}) {
    return this.StaffService.sendNotification(code)
      .pipe(
        tap((result: any) => {
          ctx.patchState({
            sendNotificationData: result,
          });
        }),
        catchError( (err: any ) => {
          ctx.patchState({
            sendNotificationData: new Error(err),
          });
          return throwError(err);
        }));
  }

  @Action(RetireOrder)
  retireOrder(ctx: StateContext<ConsignaStaffStateModel>, {code}) {
    return this.StaffService.retireOrder(code).pipe(
      tap((result: any) => {
        ctx.patchState({
          retireOrderData: result,
        });
      }),
      catchError( (err: any ) => {
        ctx.patchState({
          retireOrderData: new Error(err),
        });
        return throwError(err);
      }));
  }

  @Action(DisableLock)
  disableLock(ctx: StateContext<ConsignaStaffStateModel>, {lockerId, disable}) {
    return this.StaffService.disableLock(lockerId, disable).pipe(
      tap((result: any) => {
        ctx.patchState({
          disableLockData: result,
        });
      }),
      catchError( (err: any ) => {
        ctx.patchState({
          disableLockData: new Error(err),
        });
        return throwError(err);
      }));
  }

  @Action(FromLockersToLocker0)
  fromLockersToLocker0(ctx: StateContext<ConsignaStaffStateModel>, {code}) {
    return this.StaffService.fromLockersToLocker0(code).pipe(
      tap((result: any) => {
        ctx.patchState({
          fromLockersToLocker0Data: result,
        });
      }),
      catchError( (err: any ) => {
        ctx.patchState({
          fromLockersToLocker0Data: new Error(err),
        });
        return throwError(err);
      }));
  }

  @Action(MakeDeliveryToLocker)
  makeDeliveryToLocker(ctx: StateContext<ConsignaStaffStateModel>, {code}) {
    return this.StaffService.makeDeliveryToLocker(code).pipe(
      tap((result: any) => {
        ctx.patchState({
          makeDeliveryToLockerData: result,
        });
      }),
      catchError( (err: any ) => {
        ctx.patchState({
          makeDeliveryToLockerData: new Error(err),
        });
        return throwError(err);
      }));
  }

  @Action(MakeDeliveryToLockerFirstStep)
  makeDeliveryToLockerFirstStep(ctx: StateContext<ConsignaStaffStateModel>, {code}) {
    return this.StaffService.makeDeliveryToLockerFirstStep(code).pipe(
      tap((result: any) => {
        ctx.patchState({
          makeDeliveryToLockerFirstStepData: result,
        });
      }),
      catchError( (err: any ) => {
        ctx.patchState({
          makeDeliveryToLockerFirstStepData: new Error(err),
        });
        return throwError(err);
      }));
  }
}
