import { Alert } from '../classes/alert';

export class ShowAlert {
  static readonly type = '[ALERT] ShowAlert';
  constructor(public alert: Alert) {}
}

export class HideAlert {
  static readonly type = '[ALERT] HideAlert';
  constructor(public alert: Alert) {}
}
