import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { HideConsignaDropoffConfirmation } from './store/confirmConsignaDropoff.actions';
import { ConfirmEmitDropoffDto } from '../../../dto/confirmEmitDropoff.dto';

@Component({
  selector: 'app-confirmation-dropoff-modal',
  templateUrl: './confirmationConsignaDropoff.component.html',
  styleUrls: ['./confirmationConsignaDropoff.component.scss']
})
export class ConfirmConsignaDropoffComponent implements OnInit {

  phone: string;
  isHidden = true;
  numberLockers = '0';
  notificationType = '';
  hasFreeCold = false;
  numberColdLockers = '0';
  toEmit: ConfirmEmitDropoffDto = new ConfirmEmitDropoffDto();
  @Select(state => state.confirmationdropoff.modalData) data$: Observable<any[]>;
  @Output() emitDataAccept = new EventEmitter<ConfirmEmitDropoffDto>(null);
  @Output() emitLogoutAccept = new EventEmitter<ConfirmEmitDropoffDto>(null);
  @Select(state => state.ui.locker) locker$: Observable<any>;

  type: string;

  constructor(public store: Store) {
    this.data$.pipe().subscribe( (data: any) => {
      this.isHidden = data.hidden;
      this.type = data.type;
      this.phone = data.phone;
      this.numberLockers = data.numberLockers;
      this.numberColdLockers = data.numberColdLockers;
      this.hasFreeCold = data.hasFreeCold;
    });
  }

  ngOnInit () {
    this.locker$.subscribe( locker => {
      if (locker && locker.notificationType) {
        this.notificationType = locker.notificationType;
      }
    });
  }

  acceptButton () {
    this.toEmit.accept = true;
    this.toEmit.responseType = this.type;
    if ( this.type === 'logout' ) {
      this.emitLogoutAccept.emit(this.toEmit);
    } else {
      this.emitDataAccept.emit(this.toEmit);
    }
    this.store.dispatch(new HideConsignaDropoffConfirmation());
}

  rejectButton () {
    this.toEmit.accept = false;
    this.toEmit.responseType = '';
    if ( this.type === 'logout' ) {
      this.emitLogoutAccept.emit(this.toEmit);
    } else {
      this.emitDataAccept.emit(this.toEmit);
    }
    this.store.dispatch(new HideConsignaDropoffConfirmation());
  }
}
