import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { LogOut } from '../../../modules/cc/staff/store/authCC.actions';
import { HideHelpCCConfirmation } from '../../../modules/cc/customer/views/helpCC/store/helpCC.actions';
import { HideHelpConsignaConfirmation } from '../../../modules/consigna/customer/views/helpConsigna/store/helpConsigna.actions';
import { HideLegalTextDropoffConfirmation } from '../../../modules/consigna/customer/views/dropoff/legalText/store/legalTextConsignaDropoff.actions';
import { HideConfirmation } from '../confirmation/store/confirm.actions';
import { HideLoadingSpinner } from '../loading-spinner/store/loading-spinner.actions';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { SocketService } from '../../services/socket.service';

@Component({
  selector: 'app-screen-saver',
  templateUrl: './screen-saver.component.html',
  styleUrls: ['./screen-saver.component.scss'],
})
export class ScreenSaverComponent implements OnInit, OnDestroy {

  @Select(state => state.ui.validateToken) validateToken$: Observable<any>;

  screensaverTimeoutFn: any[] = [];
  exitStaffTimeoutFn: any[] = [];

  isLoggedIn = false;
  // screensaverTimeout = 2 * 60 * 1000; // 2min
  screensaverTimeout = 3 * 60 * 1000; // dev pruposes 10min
  exitStaffTimeout = 3 * 60 * 1000;
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onShowScreenSaver = new EventEmitter<any>();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onHideScreenSaver = new EventEmitter<any>();

  isPaused: any = false;

  hasPayment = true;

  lang = 'en';
  onlyPickupMode = true;
  showScreenSaver = false;
  showCustomerSection = false;
  text = '';
  firstTime = true;
  type = 'all';
  clientName = '';
  homeRoute: string[] = ['cc', 'home'];
  logoCAT = 'assets/images/clickme.png';
  isOnErrorSub = null;
  lockerSub = null;

  constructor(public store: Store,
    public translate: TranslateService,
    private _socketService: SocketService) {}

  ngOnInit() {
    this.initScreenSaver();
  }

  ngOnDestroy() {
    if (this.screensaverTimeoutFn) {
      this.screensaverTimeoutFn.forEach(id => clearTimeout(id));
    }
  }

  initScreenSaver() {
    console.log('initScreenSaver');
    this.validateToken$.subscribe( data => {
      console.log({data});
      if ((data && data.valid) || (localStorage.getItem('tokenval'))) {
        console.log('data valid');
        localStorage.setItem('screensaver', 'true');
        if (this.screensaverTimeoutFn) {
          this.screensaverTimeoutFn.forEach(id => clearTimeout(id));
        }
        this.observerDocument();
        if (!localStorage.getItem('remember_session')) {
          console.log('open screen saver');
          this.openScreenSaver();
        }
      }
    });
  }

  observerDocument() {
    document.getElementsByTagName('body')[0].onclick = () => {
      this.onHideScreenSaver.emit();
      if (this.screensaverTimeoutFn) {
        console.log('clear timeout');
        this.screensaverTimeoutFn.forEach(id => clearTimeout(id));
        this.screensaverTimeoutFn = [];
      }
      if (this.exitStaffTimeoutFn) {
        console.log('clear timeout 2');
        this.exitStaffTimeoutFn.forEach(id => clearTimeout(id));
        this.exitStaffTimeoutFn = [];
      }
      this.openScreenSaver();
    };
  }

  openScreenSaver() {
    if ( this.screensaverTimeout !== 0 && this.screensaverTimeout ) {
      console.log('timeout');
      console.log({screensaverTimeout: this.screensaverTimeout});
      this.screensaverTimeoutFn.push(setTimeout(() => {
        console.log('timeout fired');
        if ( localStorage.getItem( 'tokenval' ) ) {
          /* if (!this.onlyStaffFlag) {
            this.translate.use(this.lang);
            document.getElementById('screensaver').style.display = 'flex';
          } */
          const lockerid = localStorage.getItem('lockerid');
          this._socketService.sendMessage('reactiveQrCode', {lockerid});
          this.store.dispatch(new LogOut(this.showScreenSaver));
          this.onShowScreenSaver.emit();
          this.store.dispatch(new HideHelpCCConfirmation());
          this.store.dispatch(new HideHelpConsignaConfirmation());
          this.store.dispatch(new HideLegalTextDropoffConfirmation());
          this.store.dispatch(new HideConfirmation());
          this.store.dispatch(new HideLoadingSpinner());
        }
      }, this.screensaverTimeout));
    }
  }

}
