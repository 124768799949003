import { Component, OnInit } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
// tslint:disable-next-line:import-name
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../../../../environments/environment';
import { FormbuilderComponent } from '../../../../../../common/classes/form-builder-component';
import { ShowAlert } from '../../../../../../common/components/alert/store/alert.actions';
import { ManualCodeDto } from '../../../../../../common/model/manual-code.dto';
import { OrderCodeIntroduced } from '../../../store/pickup.actions';
import { ShowHelpCCConfirmation } from '../../helpCC/store/helpCC.actions';

@Component({
  selector: 'app-code',
  templateUrl: './manualCode.component.html',
  styleUrls: ['./manualCode.component.scss'],
})
export class ManualCodeComponent extends FormbuilderComponent<ManualCodeDto>
  implements OnInit {
  focused = false;
  focusedCode = false;
  okClicked = false;
  data;
  constructor(
    private activatedRoute: ActivatedRoute,
    public store: Store,
    public fb: FormBuilder,
    public translate: TranslateService,
  ) {
    super(fb);
    this.data = this.activatedRoute.snapshot.data;
  }

  ngOnInit() {
    this.createForm(new ManualCodeDto());
    this.formGroup.controls['code'].setValidators([Validators.required]);
  }

  goToScanner() {
    this.store.dispatch(new Navigate(['cc/pickup/menu']));
  }

  showResponse() {
    if (this.formGroup.valid) {
      this.store
        .dispatch(new OrderCodeIntroduced(this.formValue.code))
        .toPromise()
        .then((data) => {
          const resp =
            data && data.ccPickup && data.ccPickup.orderCodeIntroducedData
              ? data.ccPickup.orderCodeIntroducedData
              : data;
          if (resp && resp.success && !resp.isBox) {
            this.store.dispatch(
              new Navigate(['cc', 'pickup', 'scan-response'], {
                responseType: 'OK',
              }),
            );
          } else if (resp.success && resp.isBox) {
            this.store.dispatch(
              new Navigate(['cc', 'pickup', 'scan-response'], {
                responseType: 'NOTHERE',
              }),
            );
          }
        })
        .catch((err) => {
          this.store.dispatch(
            new ShowAlert({ type: 'error', text: 'MESSAGES.CODE_NOT_OK' }),
          );
        });
    }
  }

  triedToSubmit() {
    this.okClicked = true;
  }

  goHome() {
    if (environment.showQR) {
      this.store.dispatch(new Navigate(['cc', 'pickup', 'homeCC']));
    } else {
      this.store.dispatch(new Navigate(['']));
    }
    /* var ua = navigator.userAgent;
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
        ua,
      )
    ) {
      this.store.dispatch(new Navigate(['']));
    } else if (/Chrome/i.test(ua)) {
      this.store.dispatch(new Navigate(['cc', 'pickup', 'homeCC']));
    }*/

    this.store.dispatch(new Navigate(['cc/pickup/menu']));
  }

  showHelp() {
    this.store.dispatch(new ShowHelpCCConfirmation({ area: 'pickup' }));
  }
}
