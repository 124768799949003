import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../environments/environment';

@Pipe({
  name: 'assetToFullUrl',
})
export class AssetToFullUrl implements PipeTransform {
  // tslint:disable-next-line:cognitive-complexity
  transform(value: string): string {
    return `${environment.apiUrl}/${value}`;
  }
}
