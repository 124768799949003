import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { AuthToken } from '../../../common/model/auth-token.model';
import { OrderToLockerDto } from './dto/orderToLockers.dto';
import { StaffLoginDto } from './dto/staffLogin.dto';
import { ConfigModel } from '../../../common/model/config.model';
import { Observable } from 'rxjs';
import { OrderToBoxDto } from './dto/orderToBox.dto';
import { OrderToLockerFirstStepDto } from './dto/orderToLockersFirstStep.dto';

@Injectable({
  providedIn: 'root'
})
export class StaffService {

  constructor(private http: HttpClient) {}

  login(loginData: any) {
    if ( loginData && loginData.username && loginData.password) {
      // tslint:disable-next-line: no-invalid-await
      return this.http.post<AuthToken>(`${environment.apiUrl}/locker/auth/login`, loginData);
    }
    /* return this.loginByUserID(loginDto.username); */
  }

  loginByUserID(userID: string) {
    const lockerId = localStorage.getItem('lockerid');
    return this.http.post<AuthToken>(`${environment.apiUrl}/auth/log-in-by-id`, {userID, lockerId});
  }

  retireLockers(email: string, code: string, reserve_date: Date, idLockers: string[]) {
    const staff = JSON.parse(localStorage.getItem(environment.staffStorageTokenKey.cc)).email;
    return this.http.post(`${environment.apiUrl}/staff-retire-lockers`, {
      idLockers, email, code, reserve_date, staff
    });
  }
  openLockers(idLockers: string[]) {
    const staff = JSON.parse(localStorage.getItem(environment.staffStorageTokenKey.cc)).email;
    return this.http.post(`${environment.apiUrl}/open-lockers`, {
      idLockers, staff
    });
  }

  getMueble (): Observable<any> {
    const lockerId = localStorage.getItem('lockerid');
    return this.http.post(`${environment.apiUrl}/cabinet/get-data`, {lockerId});
  }

  getCCData (): Observable<any> {
    const lockerId = localStorage.getItem('lockerid');
    return this.http.post(`${environment.apiUrl}/cc-staff/get-data`, { lockerId });
  }

  setOnScreenSaverConfig (onScreenSaver: boolean): Observable<any> {
    localStorage.setItem('IsOnScreenSaver', onScreenSaver.toString());
    return this.http.post(`${environment.apiUrl}/config/set-screen-saver`, { isOnScreenSaver: onScreenSaver });
  }

  makeDeliveryToBox ( order: OrderToBoxDto ) {
    const staff = JSON.parse(localStorage.getItem(environment.staffStorageTokenKey.cc)).email;
    const lockerId = localStorage.getItem('lockerid');
    return this.http.post(`${environment.apiUrl}/cc-staff/dropoff-to-locker0`, {...order, staff, lockerId});
  }

  makeDeliveryToLocker ( order: OrderToLockerDto ) {
    const staff = JSON.parse(localStorage.getItem(environment.staffStorageTokenKey.cc)).email;
    const lockerId = localStorage.getItem('lockerid');
    return this.http.post(`${environment.apiUrl}/cc-staff/dropoff-to-locker`, {...order, staff, lockerId});
  }

  makeDeliveryToLockerFirstStep ( order: OrderToLockerFirstStepDto ) {
    const staff = JSON.parse(localStorage.getItem(environment.staffStorageTokenKey.cc)).email;
    const lockerId = localStorage.getItem('lockerid');
    return this.http.post(`${environment.apiUrl}/cc-staff/dropoff-to-locker-first-step`, {...order, staff, lockerId});
  }

  boxToCustomer ( orderId: string ) {
    const staff = JSON.parse(localStorage.getItem(environment.staffStorageTokenKey.cc)).email;
    const lockerId = localStorage.getItem('lockerid');
    return this.http.post(`${environment.apiUrl}/cc-staff/locker0-to-customer`, {orderId, staff, lockerId});
  }


  staffLogin ( staffMember: StaffLoginDto ) {
    return this.http.post(`${environment.apiUrl}/auth/log-in`, {...staffMember });
  }

  retireExpiredOrder ( dto: { orderId: string} ) {
    const staff = JSON.parse(localStorage.getItem(environment.staffStorageTokenKey.cc)).email;
    const lockerId = localStorage.getItem('lockerid');
    return this.http.post(`${environment.apiUrl}/cc-staff/retire-order`, {...dto, staff, lockerId});
  }

  openLockersByStaff ( orderId: string ) {
    const staff = JSON.parse(localStorage.getItem(environment.staffStorageTokenKey.cc)).email;
    const lockerId = localStorage.getItem('lockerid');
    return this.http.post(`${environment.apiUrl}/cc-staff/open-by-order`, {orderId, staff, lockerId});
  }

  openLockersByCoords ( x: string, y: string ) {
    const staff = JSON.parse(localStorage.getItem(environment.staffStorageTokenKey.cc)).email;
    const lockerId = localStorage.getItem('lockerid');
    return this.http.post(`${environment.apiUrl}/cc-staff/open-by-coordinates`, {x, y, staff, lockerId});
  }

  insertFakeData () {
    return this.http.post(`${environment.apiUrl}/cabinet/insert-fake-orders`, {});
  }

  getConfig() {
    const lockerId = localStorage.getItem('lockerid');
    return this.http.post<ConfigModel>(`${environment.apiUrl}/cabinet/get-data`, {lockerId});
  }

  disableLock ( lockerId: string, disable: boolean ) {
    const staff = JSON.parse(localStorage.getItem(environment.staffStorageTokenKey.cc)).email;
    const lockIdToDisable = localStorage.getItem('lockerid');
    return this.http.post(`${environment.apiUrl}/cc-staff/disable-locker`, {lockerId, disable, staff, lockIdToDisable});
  }

  fromLockersToLocker0 ( dto: { orderId: string} ) {
    const staff = JSON.parse(localStorage.getItem(environment.staffStorageTokenKey.cc)).email;
    return this.http.post(`${environment.apiUrl}/cc-staff/from-lockers-to-locker-0`, {...dto, staff});
  }
}
