import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment.prod';

@Injectable({ providedIn: 'root' })
export class TokenInterceptor implements HttpInterceptor {
    intercept(
    request: HttpRequest<any>,
    next: HttpHandler
    ): Observable<HttpEvent<any>> {

    if ( request.url.includes('cc-staff') && localStorage.getItem(environment.staffStorageTokenKey.cc) ) {
    // tslint:disable-next-line: no-parameter-reassignment
    request = request.clone({
    setHeaders: {
        Authorization: `Bearer ${localStorage.getItem(environment.staffStorageTokenKey.cc)}`,
    },
    });
}

    if ( request.url.includes('consigment-staff') && localStorage.getItem(environment.staffStorageTokenKey.consigna) ) {
        // tslint:disable-next-line: no-parameter-reassignment
        request = request.clone({
        setHeaders: {
            Authorization: `Bearer ${localStorage.getItem(environment.staffStorageTokenKey.consigna)}`,
        },
        });
}
    return next.handle(request);
}
}
