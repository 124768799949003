export class ConfirmEmitDto {
    accept: boolean;
    responseType: string;
    port: number;

    constructor() {
        this.accept = false;
        this.responseType = '';
        this.port = 0;
    }
}
