import { Component, OnInit } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-go-to-home',
  templateUrl: './go-to-home.component.html',
  styleUrls: ['./go-to-home.component.scss'],
})
export class GoToHomeComponent implements OnInit {
  constructor(public store: Store) {}

  // tslint:disable-next-line: no-empty
  ngOnInit() {}

  onGoToHome() {
    this.store.dispatch(new Navigate(['cc', 'pickup']));
  }
}
