import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Navigate } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { SyncLocker } from './modules/ui/store/ui.actions';

@Component({
  selector: 'app-root',
  template: '<app-out-of-service></app-out-of-service><app-screen-saver></app-screen-saver><app-help-consigna-modal></app-help-consigna-modal><app-help-cc-modal></app-help-cc-modal><app-loading-spinner></app-loading-spinner><app-alert></app-alert><app-lang-selector></app-lang-selector><router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {
  @Select(state => state.ui.locker) locker$: Observable<any>;
  constructor(private translateService: TranslateService, private store: Store, private route: Router) {}

  ngOnInit(): void {
    console.log('init');
    this.restoreLanguage();
    this.disableConsoleLogInProd();
    console.log('processToken');
    this.processToken();
    // this.resetRouter();
  }

  resetRouter() {
    // this.store.dispatch(new Navigate(['home']));
    this.store.dispatch(new Navigate(['pickup', 'success'], {operationId: 'ec2a0fd3-604b-4cb6-bb9f-71fe33c01626'}));
  }

  restoreLanguage() {
    if (localStorage.getItem('lang')) {
      this.translateService.use(localStorage.getItem('lang'));
    } else if (localStorage.getItem('lockerid')) {
      this.store.dispatch(new SyncLocker()).toPromise().then( resp => {
        if (resp && resp.ui && resp.ui.locker && resp.ui.locker.languages) {
          const lang = resp.ui.locker.languages[0];
          localStorage.setItem('lang', lang);
          this.translateService.use(lang);
        }
      });
    } else {
      localStorage.setItem('lang', 'es');
      this.restoreLanguage();
    }
  }

  async processToken() {
    console.log('url');
    console.log(this.route.url);
    console.log(location.href);
    console.log('tokenid: ', localStorage.getItem('tokenid'));
    console.log('socketTime: ', localStorage.getItem('socketTime'));
    if (localStorage.getItem('tokenid') && localStorage.getItem('socketTime') !== '1' && location.href.indexOf('init') === -1) {
      console.log('go to screensaver');
      localStorage.clear();
      this.store.dispatch(new Navigate(['screensaver']));
    }
  }

  disableConsoleLogInProd() {
    if (environment.production) {
      const console = {
        // tslint:disable:no-empty
        log() {},
        error() {},
        debug() {},
        dir() {},
        warn() {},
        info() {},
      };
      (<any>window).console = console;
    }
  }
}
