import { Action, State, StateContext } from '@ngxs/store';
import { HideConfirmation, ShowConfirmation } from './confirm.actions';

export class ConfirmationStateModel {
  modalData: {
    hidden: boolean;
    port: number;
    text: string;
  };
}

export const LangSelectorStateDefaults: ConfirmationStateModel = {
  modalData: {
    hidden: true,
    port: 0,
    text: ''
  }
};

@State<ConfirmationStateModel>({
  name: 'confirmation',
  defaults: LangSelectorStateDefaults,
})
export class ConfirmState {
  @Action(ShowConfirmation)
  show(ctx: StateContext<ConfirmationStateModel>, {payload}: any) {
    ctx.patchState({
      modalData: {
        port: payload.port,
        hidden: false,
        text: payload.text,
      }
    });
  }

  @Action(HideConfirmation)
  hide(ctx: StateContext<ConfirmationStateModel>) {
    ctx.patchState({
      modalData: {
      hidden: true,
      text: '',
      port: 0
      }
    });
  }

}


