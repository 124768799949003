

export class SyncLocker {
  static readonly type = '[UI] SyncLocker';
}

export class OnlyStaff {
  static readonly type = '[UI] OnlyStaff';
  constructor(public onlyStaff: boolean = false) {}
}

export class ShowVideo {
  static readonly type = '[UI] ShowVideo';
  constructor(public showVideo: boolean = false) {}
}

export class ValidateToken {
  static readonly type = '[UI] ValidateToken';
  constructor(public token: string) {}
}
