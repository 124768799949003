// tslint:disable:directive-selector
import { animate, AnimationBuilder, AnimationMetadata, AnimationPlayer, style } from '@angular/animations';
import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';

@Directive({
  selector: '[navAnimation]',
})
export class NavAnimationDirective implements OnInit, OnDestroy {
  player: AnimationPlayer;

  constructor(private builder: AnimationBuilder, private el: ElementRef) {}

  async ngOnDestroy() {
    this.toogleAnimation(false);
    await new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, 200);
    });
  }

  ngOnInit(): any {
    this.toogleAnimation(true);
  }

  toogleAnimation(show: boolean) {
    if (this.player) {
      this.player.destroy();
    }
    const metadata = show ? this.inAnimation() : this.outAnimation();
    const factory = this.builder.build(metadata);
    const player = factory.create(this.el.nativeElement);
    player.play();
  }

  private inAnimation(): AnimationMetadata[] {
    return [
      style({ transform: 'translateY(100%)' }),
      animate('200ms ease-in-out', style({ transform: 'translateY(0%)' })),
    ];
  }

  private outAnimation(): AnimationMetadata[] {
    return [style({ transform: '*' }), animate('200ms ease-in-out', style({ transform: 'translateY(100%)' }))];
  }
}
