import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
})
export class LoadingSpinnerComponent implements OnInit {
  @Select(state => state.loadingSpinner.hidden) hidden$: Observable<boolean>;

  isHidden = true;
  timeoutSpinner;
  constructor() {
    this.hidden$.pipe(delay(200)).subscribe(hidden => {
      this.isHidden = hidden;
      if (this.timeoutSpinner) {
        try {
          clearTimeout(this.timeoutSpinner);
        } catch {
          //
        }
      }
      if (!this.isHidden) {
        this.timeoutSpinner = setTimeout( () => {
          if ( !this.isHidden ) {
            this.isHidden = true;
            // this.store.dispatch(new ShowAlert({ type: 'error', text: 'GENERAL.CONNECTION_ERROR' }));
          }
        }, 30000);
      }
    });
  }

  // tslint:disable-next-line: no-empty
  ngOnInit() {}
}
