import { Navigate } from '@ngxs/router-plugin';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ShowAlert } from '../../../../common/components/alert/store/alert.actions';
import { OperationModel } from '../../../../common/model/operation.model';
import { CustomerService } from '../customer.service';
import { ClientLogout, OrderOperation, QRCodePickup, ManualCodePickup, VerifyCode, VerifyCodeEmail, OrderCodeScanned, PickupOrderLogin, OrderCodeIntroduced } from './pickup.actions';
import { ResponseTypes } from '../../../../common/classes/response.enum';

export class PickupStateModel {
  orderOperation: OperationModel;
  staffInfo: any;
  verifyCodeData: any;
  verifyCodeEmailData: any;
  orderCodeScannedData: any;
  pickUpOrderLoginData: any;
  orderCodeIntroducedData: any;

}
export const PickupStateDefaults: PickupStateModel = {
  orderOperation: null,
  staffInfo: null,
  verifyCodeData: null,
  verifyCodeEmailData: null,
  orderCodeScannedData: null,
  pickUpOrderLoginData: null,
  orderCodeIntroducedData: null
};

@State<PickupStateModel>({
  name: 'ccPickup',
  defaults: PickupStateDefaults,
})
export class PickupState {
  constructor(
    private CustomerService: CustomerService,
    private store: Store
    ) {}

    @Action(QRCodePickup)
    qrCodeScan(ctx: StateContext<PickupStateModel>, {code}: QRCodePickup) {
      return this.CustomerService.verifyCode(code).pipe(
        tap(res => {
          if (res && res.status === ResponseTypes.QR_FIND_ORDER) {
            ctx.patchState({
              orderOperation: res.data,
            });
            // this.store.dispatch(new Navigate(['pickup', 'pickup']));
            //  OPEN ORDER PAGE
          }
          if (res && res.status === ResponseTypes.QR_LOGIN_STAFF) {
            ctx.patchState({
              staffInfo: res.data,
            });
            //  OPEN STAFF PAGE
          }
          if (res && res.status === ResponseTypes.QR_BOX_0) {
            //  SHOW MESSAGE REDIRECT TO BOX
          }
        }),
        catchError(err => {
          ctx.patchState({
            orderOperation: null,
            staffInfo: null
          });
          if ( err.status === 404) {
           // SHOW MESSAGES RESPONSE_NOT ctx.dispatch(new ShowAlert({ type: 'error', text: 'MESSAGES.RESPONSE_NOT' }));
          }
          return throwError(err);
        }),
      );
    }

    @Action(ManualCodePickup)
    manualCodePickup(ctx: StateContext<PickupStateModel>, {code, email}: ManualCodePickup) {
      return this.CustomerService.verifyCodeEmail(code, email).pipe(
        tap(res => {
          if (res && res.status === ResponseTypes.QR_FIND_ORDER) {
            ctx.patchState({
              orderOperation: res.data,
            });
            // this.store.dispatch(new Navigate(['pickup', 'pickup']));
            //  OPEN ORDER PAGE
          }
          if (res && res.status === ResponseTypes.QR_BOX_0) {
            //  SHOW MESSAGE REDIRECT TO BOX
          }
        }),
        // tslint:disable-next-line: no-identical-functions
        catchError(err => {
          ctx.patchState({
            orderOperation: null,
            staffInfo: null
          });
          if ( err.status === 404) {
           // SHOW MESSAGES RESPONSE_NOT ctx.dispatch(new ShowAlert({ type: 'error', text: 'MESSAGES.RESPONSE_NOT' }));
          }
          return throwError(err);
        }),
      );
    }


  @Action(OrderOperation)
  orderOperration(ctx: StateContext<PickupStateModel>, {code}: OrderOperation) {
    return this.CustomerService.verifyCode(code).pipe(
      tap(res => {
        ctx.patchState({
          orderOperation: res.data,
        });
        this.store.dispatch(new Navigate(['pickup', 'pickup']));
      }),
      catchError(err => {
        ctx.patchState({
          orderOperation: null,
        });
        if ( err.status === 502) {
          ctx.dispatch(new ShowAlert({ type: 'error', text: 'MESSAGES.NETWORK_ERROR' }));
        } else {
          ctx.dispatch(new ShowAlert({ type: 'error', text: 'MESSAGES.CODE_NOT_FOUND' }));
        }
        return throwError(err);
      }),
    );
  }

  @Action(ClientLogout)
  clientLogout(ctx: StateContext<PickupStateModel>) {
    ctx.patchState({
      orderOperation: null,
    });
    this.store.dispatch(new Navigate(['home']));
  }

  @Action(VerifyCode)
  verifyCode(ctx: StateContext<PickupStateModel>, {code}: OrderOperation) {
    return this.CustomerService.verifyCode(code).pipe(
      tap((result: any) => {
        ctx.patchState({
          verifyCodeData: result,
        });
      }),
      catchError( (err: any ) => {
        ctx.patchState({
          verifyCodeData: new Error(err),
        });
        return throwError(err);
      }));
  }

  @Action(VerifyCodeEmail)
  verifyCodeEmail(ctx: StateContext<PickupStateModel>, {code, email}) {
    return this.CustomerService.verifyCodeEmail(code, email).pipe(
      tap((result: any) => {
        ctx.patchState({
          verifyCodeEmailData: result,
        });
      }),
      catchError( (err: any ) => {
        ctx.patchState({
          verifyCodeEmailData: new Error(err),
        });
        return throwError(err);
      }));
  }

  @Action(OrderCodeScanned)
  orderCodeScanned(ctx: StateContext<PickupStateModel>, {code}: OrderOperation) {
    return this.CustomerService.orderCodeScanned(code).pipe(
      tap((result: any) => {
        ctx.patchState({
          orderCodeScannedData: result,
        });
      }),
      catchError( (err: any ) => {
        ctx.patchState({
          orderCodeScannedData: new Error(err),
        });
        return throwError(err);
      }));
  }

  @Action(OrderCodeIntroduced)
  orderCodeIntroduced(ctx: StateContext<PickupStateModel>, {code}: OrderOperation) {
    return this.CustomerService.orderCodeScanned(code).pipe(
      tap((result: any) => {
        ctx.patchState({
          orderCodeIntroducedData: result,
        });
      }),
      catchError( (err: any ) => {
        ctx.patchState({
          orderCodeIntroducedData: new Error(err),
        });
        return throwError(err);
      }));
  }
  @Action(PickupOrderLogin)
  pickupOrderLogin(ctx: StateContext<PickupStateModel>, {orderId, email}) {
    return this.CustomerService.pickupOrderLogin(orderId, email).pipe(
      tap((result: any) => {
        ctx.patchState({
          pickUpOrderLoginData: result,
        });
      }),
      catchError( (err: any ) => {
        ctx.patchState({
          pickUpOrderLoginData: new Error(err),
        });
        return throwError(err);
      }));
  }
}
