export class LogInDto {
  username: string;
  password: string;
  lockerId: string;
  rememberSession: boolean;

  constructor() {
    this.username = null;
    this.password = null;
    this.lockerId = null;
    this.rememberSession = null;
  }
}
