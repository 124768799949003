import { Component, OnInit } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-not-foud',
  templateUrl: './not-foud.component.html',
  styleUrls: ['./not-foud.component.scss']
})
export class NotFoudComponent implements OnInit {
notfoundLogo = 'assets/images/network_error2.png';
  constructor(public store: Store) { }

  ngOnInit() {
    // STUFF HERE
  }
  goHome() {
    this.store.dispatch(new Navigate(['home']));
  }
}
