
export class PickUpCodeDto {

    code: string;
    lockerId: string;

    constructor () {
        this.code = '';
    }
}
