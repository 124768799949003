import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SendOpenScreenService {

  constructor(private http: HttpClient) {}

  sendOpenScreen ( password: string ) {
    const staff = JSON.parse(localStorage.getItem(environment.staffStorageTokenKey.consigna)).email;
    return this.http.post(`${environment.apiUrl}/consigment-staff/open-screen-lock`, {password, staff});
  }
}
