// https://github.com/LukasMarx/angular-dynamic-components

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from '../../pipes/pipes.module';
import { DirectivesModule } from '../directives/directives.module';
import { InsertionDirective } from './insertion.directive';
import { ModalComponent } from './modal.component';


@NgModule({
  imports: [CommonModule, PipesModule, DirectivesModule],
  declarations: [ModalComponent, InsertionDirective],
  entryComponents: [ModalComponent],
})
export class ModalModule {}
