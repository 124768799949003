import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { HideOpenScreen } from './store/openscreen.actions';
import { SendOpenScreenService } from '../../services/sendOpenScreen.service';
import { ShowAlert } from '../alert/store/alert.actions';

@Component({
  selector: 'app-open-screen-modal',
  templateUrl: './openScreen.component.html',
  styleUrls: ['./openScreen.component.scss']
})
export class OpenScreenComponent implements OnInit {

  isHidden = true;
  password = '';
  @Select(state => state.openScreen.modalData) data$: Observable<any[]>;

  constructor(public store: Store, private sendOpenScreen: SendOpenScreenService) {
    this.data$.pipe().subscribe( (data: any) => {
      this.isHidden = data.hidden;
    });
  }

  ngOnInit() {
    //
  }

  acceptButton () {
    this.sendOpenScreen.sendOpenScreen(this.password).toPromise()
    .then( res => this.store.dispatch( new ShowAlert({ type: 'success', text: 'GENERAL.SCREEN_OPENED' })))
    .catch( err => this.store.dispatch( new ShowAlert({ type: 'error', text: 'GENERAL.SCREEN_NOT_OPENED' })));
    this.password = '';
    this.store.dispatch(new HideOpenScreen());
}

  rejectButton () {
    this.password = '';
    this.store.dispatch(new HideOpenScreen());
  }
}
