import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { AuthToken } from '../../../common/model/auth-token.model';
import { LogInDto } from './classes/log-in.dto';
import { StaffLoginDto } from './dto/staffLogin.dto';
import { ConfigModel } from '../../../common/model/config.model';
import { Observable } from 'rxjs';
import { OrderToLockerDto } from './dto/orderToLockers.dto';
import { OrderToLockerFirstStepDto } from './store/orderToLockersFirstStep.dto';

@Injectable({
  providedIn: 'root'
})
export class StaffConsignaService {

  constructor(private http: HttpClient) {}

  login(loginDto: LogInDto) {
    if (loginDto.username && loginDto.password) {
      // tslint:disable-next-line: no-invalid-await
      return this.http.post<AuthToken>(`${environment.apiUrl}/auth/log-in`, loginDto);
    }
    /* return this.loginByUserID(loginDto.username); */
  }

  loginByUserID(userID: string) {
    const lockerId = localStorage.getItem('lockerid');
    return this.http.post<AuthToken>(`${environment.apiUrl}/locker/auth/login-by-id`, {userID, lockerId});
  }

  getMueble (): Observable<any> {
    const lockerId = localStorage.getItem('lockerid');
    return this.http.post(`${environment.apiUrl}/cabinet/get-data`, {lockerId});
  }

  getConsignaData (): Observable<any> {
    const lockerId = localStorage.getItem('lockerid');
    return this.http.post(`${environment.apiUrl}/consigment-staff/get-data`, {lockerId});
  }


  staffLogin ( staffMember: StaffLoginDto ) {
    return this.http.post(`${environment.apiUrl}/auth/log-in`, staffMember);
  }

  openByOrder ( code: string ) {
    const staff = JSON.parse(localStorage.getItem(environment.staffStorageTokenKey.consigna)).email;
    const lockerId = localStorage.getItem('lockerid');
    return this.http.post(`${environment.apiUrl}/consigment-staff/open-by-code`, {code, staff, lockerId});
  }

  openLockersByCoords ( x: string, y: string ) {
    const staff = JSON.parse(localStorage.getItem(environment.staffStorageTokenKey.consigna)).email;
    const lockerId = localStorage.getItem('lockerid');
    return this.http.post(`${environment.apiUrl}/consigment-staff/open-by-coordinates`, {x, y, staff, lockerId});
  }

  retireOrder ( code: string ) {
    const staff = JSON.parse(localStorage.getItem(environment.staffStorageTokenKey.consigna)).email;
    const lockerId = localStorage.getItem('lockerid');
    return this.http.post(`${environment.apiUrl}/consigment-staff/retire-order-staff`, {code, staff, lockerId});
  }

  sendNotification ( code: string ) {
    const staff = JSON.parse(localStorage.getItem(environment.staffStorageTokenKey.consigna)).email;
    const lockerId = localStorage.getItem('lockerid');
    return this.http.post(`${environment.apiUrl}/consigment-staff/resend-notification`, {code, staff, lockerId});
  }

  getConfig() {
    return this.http.get<ConfigModel>(`${environment.apiUrl}/config`);
  }

  disableLock ( lockerId: string, disable: boolean ) {
    const staff = JSON.parse(localStorage.getItem(environment.staffStorageTokenKey.consigna)).email;
    const lockIdToDisable = localStorage.getItem('lockerid');
    return this.http.post(`${environment.apiUrl}/consigment-staff/disable-locker`, {lockerId, disable, staff, lockIdToDisable});
  }

  fromLockersToLocker0 ( code: string ) {
    const staff = JSON.parse(localStorage.getItem(environment.staffStorageTokenKey.consigna)).email;
    return this.http.post(`${environment.apiUrl}/consigment-staff/from-lockers-to-locker0`, {code, staff});
  }

  makeDeliveryToLocker ( order: OrderToLockerDto ) {
    const staff = JSON.parse(localStorage.getItem(environment.staffStorageTokenKey.cc)).email;
    return this.http.post(`${environment.apiUrl}/consigment-staff/dropoff-consignment`, {...order, staff});
  }

  makeDeliveryToLockerFirstStep ( order: OrderToLockerFirstStepDto ) {
    const staff = JSON.parse(localStorage.getItem(environment.staffStorageTokenKey.cc)).email;
    return this.http.post(`${environment.apiUrl}/consigment-staff/dropoff-consignment-first-step`, {...order, staff});
  }
}
