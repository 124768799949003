export class ShowLegalTextDropoffConfirmation {
  static readonly type = '[LEGALTEXTDROPOFF] Show retire';

  constructor (public payload: any) {
    //
  }
}

export class HideLegalTextDropoffConfirmation {
  static readonly type = '[LEGALTEXTDROPOFF] Hide';
}

