// tslint:disable:ordered-imports
import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import es from '@angular/common/locales/es';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsModule } from '@ngxs/store';
import { fr_FR, NZ_I18N, NzFormModule, NgZorroAntdModule } from 'ng-zorro-antd';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoadingSpinnerrState } from './common/components/loading-spinner/store/loading-spinner.state';
import { LoadingSpinnerInterceptor } from './common/interceptors/loading-spinner.interceptor';
import { LoggerInterceptor } from './common/interceptors/logger.interceptor';
import { SharedModule } from './common/shared.module';
import { IconsProviderModule } from './icons-provider.module';
import { RatingModule } from 'ng-starrating';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { PickupState } from './modules/cc/customer/store/pickup.state';
import { CCAuthState } from './modules/cc/staff/store/authCC.state';
import { environment } from '../environments/environment';
import { AfterScanComponent } from './modules/cc/customer/views/pickup/afterScan/afterScan.component';
import { CustomerModule } from './modules/cc/customer/customer.module';
import { ModalModule } from './common/components/modal/modal.module';
import { AlertState } from './common/components/alert/store/alert.state';
import { LangSelectorState } from './modules/ui/views/langSelector/store/langSelector.state';
import { UIState } from './modules/ui/store/ui.state';
import { ConsignaStaffState } from './modules/consigna/staff/store/authConsigna.state';
import { TokenInterceptor } from './common/interceptors/auth.interceptor';
import { ConfirmConsignaDropoffState } from './modules/consigna/customer/views/dropoff/confirmation/store/confirmConsignaDropoff.state';
import { LegalTextConsignaDropoffState } from './modules/consigna/customer/views/dropoff/legalText/store/legalTextCConsignaDropoff.state';
import { HelpCCState } from './modules/cc/customer/views/helpCC/store/helpCC.state';
import { HelpConsignaState } from './modules/consigna/customer/views/helpConsigna/store/helpConsigna.state';
import { ConfirmState } from './common/components/confirmation/store/confirm.state';
import { ConsignaCustomerState } from './modules/consigna/customer/store/consingaCustomer.state';
import { CCStaffState } from './modules/cc/staff/store/ccStaff.state';
import { ConsignaStaffApiState } from './modules/consigna/staff/store/consignaStaff.state';
import { GlobalHttpInterceptorService } from './common/interceptors/error.interceptor';
import { OpenScreenState } from './common/components/openScreen/store/openscreen.state';



registerLocaleData(es);

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    IconsProviderModule,
    NzNotificationModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RatingModule,
    CustomerModule,
    NgbModule,
    ModalModule,
    NzFormModule,
    NgZorroAntdModule,
    NgxsModule.forRoot([LoadingSpinnerrState, AlertState,  PickupState, ConsignaStaffState, HelpCCState, ConsignaCustomerState, HelpConsignaState, CCAuthState, CCStaffState, ConsignaStaffApiState, ConsignaStaffState, LangSelectorState, LegalTextConsignaDropoffState, ConfirmConsignaDropoffState, ConfirmState, UIState, OpenScreenState ], {
      developmentMode: !environment.production,
    }),
    NgxsLoggerPluginModule.forRoot({ disabled: environment.production }),
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
    NgxsRouterPluginModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    SharedModule,
  ],
  entryComponents: [AfterScanComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    { provide: NZ_I18N, useValue: fr_FR },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoggerInterceptor,
      multi: true,
    },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingSpinnerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GlobalHttpInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

// tslint:disable-next-line:function-name
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/');
}
