import { Action, State, StateContext } from '@ngxs/store';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { UiService } from '../ui.service';
import { OnlyStaff, ShowVideo, SyncLocker, ValidateToken } from './ui.actions';

export class UIStateModel {
  locker: any;
  onlyStaff: boolean;
  showVideo: boolean;
  validateToken: any;
}
export const PickupStateDefaults: UIStateModel = {
  locker: null,
  onlyStaff: null,
  showVideo: null,
  validateToken: null
};

@State<UIStateModel>({
  name: 'ui',
  defaults: PickupStateDefaults,
})
export class UIState {
  constructor(
    private uiService: UiService
    ) {}

    @Action(SyncLocker)
    syncLocker(ctx: StateContext<UIStateModel>) {
      return this.uiService.syncMueble().pipe(
        tap(res => {
          ctx.patchState({
            locker: res,
          });
        }),
        // tslint:disable-next-line: no-identical-functions
        catchError(err => {
          ctx.patchState({
            locker: null
          });
          return throwError(err);
        }),
      );
    }

    @Action(OnlyStaff)
    onlyStaff(ctx: StateContext<UIStateModel>, {onlyStaff}) {
      ctx.patchState({
        onlyStaff
      });
    }

    @Action(ShowVideo)
    showVideo(ctx: StateContext<UIStateModel>, {showVideo}) {
      ctx.patchState({
        showVideo
      });
    }

    @Action(ValidateToken)
    validateToken(ctx: StateContext<UIStateModel>, {token}) {
      console.log('validate token--');
      return this.uiService.validateToken(token).pipe(
        tap(res => {
          ctx.patchState({
            validateToken: {
              lockerId: res.lockerId,
              valid: true
            },
          });
        }),
        // tslint:disable-next-line: no-identical-functions
        catchError(err => {
          ctx.patchState({
            validateToken: null
          });
          return throwError(err);
        }),
      );
    }

}
