import { Injectable } from '@angular/core';
import { io } from 'socket.io-client';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  private socket;
  user: any;

  constructor() {
    this.connectSocket();
  }

  connectSocket() {
    console.log('connect socket');
    const lockerid = localStorage.getItem('lockerid');
    const tokenid = localStorage.getItem('tokenid');
    if (lockerid && tokenid) {
      const time = (localStorage.getItem('socketTime')) ? parseInt(localStorage.getItem('socketTime'), 10) + 1 : 1;
      localStorage.setItem('socketTime', time.toString());
      console.log({lockerid, time});
      if (time === 1) {
        this.socket = io(environment.socketURL,
          {
            query: {
              lockerid,
              locker: `P${lockerid}-pwa`,
              ispwa: true
            }
          });
      }
    }
  }

  getSocket() {
    if (!this.socket) {
      this.connectSocket();
    }
    return (this.socket) ? this.socket : false;
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  }

  onNewMessage() {
    if (!this.socket) {
      this.connectSocket();
    }
    return new Observable(observer => {
      this.socket.on('newenvent', msg => {
        observer.next(msg);
      });
    });
  }

  sendMessage(channel: string, messageData: any) {
    if (!this.socket) {
      this.connectSocket();
    }
    const lockerId = localStorage.getItem('lockerid');
    messageData.locker = `P${lockerId}`;
    messageData.obj = {
      lockerid: lockerId
    };
    if (this.socket) {
      this.socket.emit(channel, messageData, (response) => {
        console.log({response});
      });
    }

  }
}
