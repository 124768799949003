export class ShowHelpConsignaConfirmation {
  static readonly type = '[HELPCONSIGNA] Show retire';

  constructor (public payload: any) {
    //
  }
}

export class HideHelpConsignaConfirmation {
  static readonly type = '[HELPCONSIGNA] Hide';
}

