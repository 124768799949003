import { Action, State, StateContext } from '@ngxs/store';
import { HideOpenScreen, ShowOpenScreen } from './openscreen.actions';

export class OpenScreenStateModel {
  modalData: {
    hidden: boolean;
  };
}

export const OpenScreenStateDefaults: OpenScreenStateModel = {
  modalData: {
    hidden: true,
  }
};

@State<OpenScreenStateModel>({
  name: 'openScreen',
  defaults: OpenScreenStateDefaults,
})
export class OpenScreenState {
  @Action(ShowOpenScreen)
  show(ctx: StateContext<OpenScreenStateModel>, {payload}: any) {
    ctx.patchState({
      modalData: {
        hidden: false,
      }
    });
  }

  @Action(HideOpenScreen)
  hide(ctx: StateContext<OpenScreenStateModel>) {
    ctx.patchState({
      modalData: {
      hidden: true,
      }
    });
  }

}


