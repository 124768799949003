import { Action, State, StateContext } from '@ngxs/store';
import { HideHelpConsignaConfirmation, ShowHelpConsignaConfirmation } from './helpConsigna.actions';

export class HelpConsignaStateModel {
  modalData: {
    hidden: boolean;
    area: string;
  };
}

export const HelpConsignaStateDefaults: HelpConsignaStateModel = {
  modalData: {
    hidden: true,
    area: ''

  }
};

@State<HelpConsignaStateModel>({
  name: 'helpconsignadropoff',
  defaults: HelpConsignaStateDefaults,
})
export class HelpConsignaState {
  @Action(ShowHelpConsignaConfirmation)
  show(ctx: StateContext<HelpConsignaStateModel>, {payload}: any) {
    ctx.patchState({
      modalData: {
        hidden: false,
        area: payload.area
      }
    });
  }

  @Action(HideHelpConsignaConfirmation)
  hide(ctx: StateContext<HelpConsignaStateModel>) {
    ctx.patchState({
      modalData: {
      hidden: true,
      area: ''
      }
    });
  }

}


