import { HttpErrorResponse } from '@angular/common/http';
import { Navigate } from '@ngxs/router-plugin';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ShowAlert } from '../../../../common/components/alert/store/alert.actions';
import { AuthToken } from '../../../../common/model/auth-token.model';
import { StaffConsignaService } from '../staff.service';
import { LogOut, SyncConsignaLocker, LogInConsigna } from './authConsigna.actions';
import { environment } from '../../../../../environments/environment.prod';
import { ScreenSaverService } from '../../../../common/services/screenSaver.service';

export class ConsignaStaffStateModel {
  authToken: AuthToken;
  lockerConsigna: any;
}

export const ConsignaStaffStateDefaults: ConsignaStaffStateModel = {
  authToken: null,
  lockerConsigna: null
};

@State<ConsignaStaffStateModel>({
  name: 'cosnignaStaff',
  defaults: ConsignaStaffStateDefaults,
})
export class ConsignaStaffState {
  constructor(
    private store: Store,
    private staffService: StaffConsignaService,
    private ssService: ScreenSaverService
    ) {}

  @Selector()
  static authToken(state: ConsignaStaffStateModel) {
    return state.authToken;
  }

  setTokenKey(result, payload) {
    localStorage.setItem('lockerid', payload.lockerId);
    localStorage.setItem('role', result.role);
    if (result.role === 'staffAll' || result.role === 'staffCC') {
      localStorage.setItem( environment.staffStorageTokenKey.cc, JSON.stringify(result));
    }
    if (result.role === 'staffAll' || result.role === 'staffConsigment') {
      localStorage.setItem( environment.staffStorageTokenKey.consigna, JSON.stringify(result));
    }
  }

  @Action(LogInConsigna)
  logIn(ctx: StateContext<ConsignaStaffStateModel>, { payload }) {
    return this.staffService.login(payload).pipe(
      tap((result: any) => {
        this.setTokenKey(result, payload);
        ctx.patchState({
          authToken: result,
        });
        this.ssService.staffLoggedIn$.next(true);
        if (result.role === 'staffAll' || result.role === 'staffConsigment') {
          ctx.dispatch(new Navigate(['consigna', 'staff', 'staff-menu']));
        } else if (result.role === 'staffCC') {
          ctx.dispatch(new Navigate(['cc', 'staff', 'staff-menu']));
        }
      }),
      // tslint:disable-next-line:no-identical-functions
      catchError((err: HttpErrorResponse) => {
        console.log('LOGIN_ERROR -> ', err);
        if ( err && err.error && err.error.message === 'INVALID_EMAIL') {
          ctx.dispatch(new ShowAlert({ type: 'error', text: 'MESSAGES.STAFF_USER_INCORRECT' }));
        }
        if ( err && err.error && err.error.message === 'INVALID_PASSWORD') {
          ctx.dispatch(new ShowAlert({ type: 'error', text: 'MESSAGES.STAFF_PASSWORD_INCORRECT' }));
        }
        return throwError(err);
      }),
    );
  }

  @Action(LogOut)
  logOut(ctx: StateContext<ConsignaStaffStateModel>, {showScreenSaver}) {
    localStorage.removeItem('token');
    localStorage.removeItem(environment.staffStorageTokenKey.cc);
    localStorage.removeItem(environment.staffStorageTokenKey.consigna);
    ctx.patchState({
      authToken: null,
    });
    this.ssService.staffLoggedIn$.next(false);
    if (!showScreenSaver) {
      this.store.dispatch(new Navigate(['home']));
    } else {
      this.store.dispatch(new Navigate(['screensaver']));
    }
  }

  @Action(SyncConsignaLocker)
    syncConsignaLocker(ctx: StateContext<ConsignaStaffStateModel>) {
      return this.staffService.getConsignaData().pipe(
        tap(res => {
          ctx.patchState({
            lockerConsigna: res,
          });
        }),
        // tslint:disable-next-line: no-identical-functions
        catchError(err => {
          ctx.patchState({
            lockerConsigna: null
          });
          return throwError(err);
        }),
      );
    }
}
