import { LogInDto } from '../classes/log-in.dto';

export class LogInCC {
  static readonly type = '[STAFF] LogInCC';
  constructor(public payload: LogInDto) {}
}

export class LogOut {
  static readonly type = '[STAFF] LogOut';
  constructor(public showScreenSaver: boolean = false) {}
}

export class ScreenSaverOn {
  static readonly type = '[STAFF] ScreenSaverOn';
}

export class ScreenSaverOff {
  static readonly type = '[STAFF] ScreenSaverOff';
}

export class SyncCCLocker {
  static readonly type = '[STAFF] SyncCCLocker';
}

export class LoginByIdCC {
  static readonly type = '[CONSIGNASTAFF] LoginByIdCC';
  constructor(public id: string ) {}
}
