export class ShowConfirmation {
  static readonly type = '[CONFIRMATION] Show retire';

  constructor (public payload: any) {
    //
  }
}

export class HideConfirmation {
  static readonly type = '[CONFIRMATION] Hide';
}

