import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { HideConfirmation } from './store/confirm.actions';
import { ConfirmEmitDto } from './dto/confirmEmit.dto';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmComponent implements OnInit {

  port = 0;
  text = '';
  order: string;
  isHidden = true;
  numberLockers = 0;
  toEmit: ConfirmEmitDto = new ConfirmEmitDto();
  @Select(state => state.confirmation.modalData) data$: Observable<any[]>;
  @Output() emitDataAccept = new EventEmitter<ConfirmEmitDto>(null);
  @Output() emitDataAccept2 = new EventEmitter<ConfirmEmitDto>(null);
  @Output() emitLogoutAccept = new EventEmitter<ConfirmEmitDto>(null);

  type: string;

  QRSephora = 'assets/images/QRSephora.png';
  background = 'assets/images/brushes.jpg';
  constructor(public store: Store) {
    this.data$.pipe().subscribe( (data: any) => {
      this.isHidden = data.hidden;
      this.port = data.port,
      this.text = data.text;
    });
  }

  ngOnInit() {
    //
  }

  acceptButton () {
    this.toEmit.accept = true;
    this.toEmit.responseType = this.type;
    this.toEmit.port = this.port;
    if ( this.port === 100 ) {
      this.emitLogoutAccept.emit(this.toEmit);
    } else {
      this.emitDataAccept.emit(this.toEmit);
    }
    this.store.dispatch(new HideConfirmation());
}

  rejectButton () {
    this.toEmit.accept = false;
    this.toEmit.responseType = '';
    if ( this.port === 100  ) {
      this.emitLogoutAccept.emit(this.toEmit);
    } else {
      this.emitDataAccept.emit(this.toEmit);
    }
    this.store.dispatch(new HideConfirmation());
  }
}
