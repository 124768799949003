import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Alert } from './classes/alert';
import { HideAlert } from './store/alert.actions';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {
  @Select(state => state.alert.alerts) alerts$: Observable<Alert[]>;

  constructor(public store: Store) {}

  // tslint:disable-next-line: no-empty
  ngOnInit() {}

  onClickAlert(alert: Alert) {
    this.store.dispatch(new HideAlert(alert));
  }
}
