export class ShowOpenScreen {
  static readonly type = '[OPENSCREEN] Show retire';

  constructor (public payload: any) {
    //
  }
}

export class HideOpenScreen {
  static readonly type = '[OPENSCREEN] Hide';
}

