import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AlertComponent } from './components/alert/alert.component';
import { GoToHomeComponent } from './components/go-to-home/go-to-home.component';
import { UserProfileComponent } from './components/user-profile/user-profile';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { AppFooterComponent } from './components/app-footer/app-footer.component';
import { AppHeaderComponent } from './components/app-header/app-header.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { NotFoudComponent } from './components/not-foud/not-foud.component';
import { ScreenSaverComponent } from './components/screen-saver/screen-saver.component';
import { PipesModule } from './pipes/pipes.module';
import { LangSelectorComponent } from '../modules/ui/views/langSelector/langSelector.component';
import { ConfirmConsignaDropoffComponent } from '../modules/consigna/customer/views/dropoff/confirmation/confirmationConsignaDropoff.component';
import { LegalTextComponent } from '../modules/consigna/customer/views/dropoff/legalText/legalText.component';
import { HelpConsignaComponent } from '../modules/consigna/customer/views/helpConsigna/helpConsigna.component';
import { HelpCCComponent } from '../modules/cc/customer/views/helpCC/helpCC.component';
import { ConfirmComponent } from './components/confirmation/confirmation.component';
import { OpenScreenComponent } from './components/openScreen/openScreen.component';
import { OutOfServiceComponent } from './components/outOfService/outOfService.component';
import { StaffButtonsComponent } from './components/staff-buttons/staff-buttons.component';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, HttpClientModule, PipesModule, TranslateModule, NgZorroAntdModule],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgZorroAntdModule,
    PipesModule,
    TranslateModule,
    GoToHomeComponent,
    ScreenSaverComponent,
    OutOfServiceComponent,
    AlertComponent,
    LoadingSpinnerComponent,
    LangSelectorComponent,
    UserProfileComponent,
    AppHeaderComponent,
    AppFooterComponent,
    ConfirmComponent,
    ConfirmConsignaDropoffComponent,
    LegalTextComponent,
    HelpConsignaComponent,
    HelpCCComponent,
    OpenScreenComponent,
    StaffButtonsComponent
  ],
  declarations: [ OutOfServiceComponent, OpenScreenComponent, LangSelectorComponent, HelpConsignaComponent, HelpCCComponent, LegalTextComponent, ConfirmConsignaDropoffComponent, ConfirmComponent, GoToHomeComponent, ScreenSaverComponent, AlertComponent, LoadingSpinnerComponent, UserProfileComponent, AppHeaderComponent, AppFooterComponent, NotFoudComponent, StaffButtonsComponent],
})
export class SharedModule {}
