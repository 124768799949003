import { Component } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { HideHelpCCConfirmation } from './store/helpCC.actions';

@Component({
  selector: 'app-help-cc-modal',
  templateUrl: './helpCC.component.html',
  styleUrls: ['./helpCC.component.scss']
})
export class HelpCCComponent {

  isHidden = true;
  area = '';
  @Select(state => state.helpccdropoff.modalData) data$: Observable<any[]>;

  QRSephora = 'assets/images/QRSephora.png';
  background = 'assets/images/brushes.jpg';
  constructor(public store: Store) {
    this.data$.pipe().subscribe( (data: any) => {
      this.isHidden = data.hidden;
      this.area = data.area;
    });
  }

  acceptButton () {
    this.store.dispatch(new HideHelpCCConfirmation());
  }

}
