import { ClientToLockersDto } from '../dto/clientToLockers.dto';
import { PickUpCodeDto } from '../dto/pickupCodeDto';

export class GetMuebleConsigna {
  static readonly type = '[CONSIGNACUSTOMER] GetMuebleConsigna';
}

export class GetConsignaData {
  static readonly type = '[CONSIGNACUSTOMER] GetConsignaData';
}

export class ClientToLocker {
  static readonly type = '[CONSIGNACUSTOMER] ClientToLocker';
  constructor(public data: ClientToLockersDto) {}
}

export class PickupFromLocker {
  static readonly type = '[CONSIGNACUSTOMER] PickupFromLocker';
  constructor(public data: PickUpCodeDto) {}
}

export class DropoffConfirmation {
  static readonly type = '[CONSIGNACUSTOMER] DropoffConfirmation';
  constructor(public data: {id: string, code: string}) {}
}
