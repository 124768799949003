import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.scss']
})
export class AppFooterComponent implements OnInit {
  @Select(state => state.ui.locker) mueble$: Observable<any>;
  logoHMY = 'assets/images/logo-footer.png';
  logoHMYMobile = 'assets/images/logoYudigar.png';
  version = environment.version;
  muebleId = '';

  @Input() isUser = 'false';
  // tslint:disable-next-line: no-empty
  constructor( public store: Store ) { }

  // tslint:disable-next-line: no-empty
  ngOnInit() {
    this.mueble$.subscribe( mueble => {
      if (mueble) {
        this.muebleId = mueble.lockerId;
      }
    });
  }

  /* insertFakeData () {
    this.service.insertFakeData().subscribe( res => {
      this.store.dispatch(new SyncCCLocker);
    });
  } */
}
