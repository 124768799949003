import { TranslateService } from '@ngx-translate/core';
import { Action, State, StateContext } from '@ngxs/store';
import { Alert } from '../classes/alert';
import { HideAlert, ShowAlert } from './alert.actions';

export class AlertStateModel {
  alerts: Alert[];
}
export const AlertStateDefaults: AlertStateModel = {
  alerts: [],
};

@State<AlertStateModel>({
  name: 'alert',
  defaults: AlertStateDefaults,
})
export class AlertState {
  constructor(private translateService: TranslateService) {}

  @Action(ShowAlert)
  async showAlert(ctx: StateContext<AlertStateModel>, { alert }: ShowAlert) {
    ctx.patchState({
      alerts: [],
    }); // ALWAYS REMOVE OLD ALERTS FIRST
    const state = ctx.getState();
    const newText = await this.translateService.get(alert.text).toPromise();
    const newAlert = { ...alert, id: new Date().getTime(), text: newText };
    ctx.patchState({
      alerts: [newAlert, ...state.alerts],
    });
    setTimeout(() => {
      ctx.dispatch(new HideAlert(newAlert));
    }, 5000);
  }

  @Action(HideAlert)
  hideAlert(ctx: StateContext<AlertStateModel>, { alert }: HideAlert) {
    const state = ctx.getState();
    ctx.patchState({
      alerts: state.alerts.filter(a => a.id !== alert.id),
    });
  }
}
