import { Action, State, StateContext } from '@ngxs/store';
import { GetMuebleConsigna, GetConsignaData, ClientToLocker, PickupFromLocker, DropoffConfirmation } from './consignaCustomer.actions';
import { CustomerConsignaService } from '../customerConsigna.service';
import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';

export class ConsignaCustomerStateModel {
  muebleConsigna: any;
  consignaData: any;
  clientToLockerData: any;
  pickupFromLockerData: any;
  dropoffConfirmationData: any;

}
export const ConsignaCustomerStateDefaults: ConsignaCustomerStateModel = {
  muebleConsigna: null,
  consignaData: null,
  clientToLockerData: null,
  pickupFromLockerData: null,
  dropoffConfirmationData: null
};

@State<ConsignaCustomerStateModel>({
  name: 'consignaCustomer',
  defaults: ConsignaCustomerStateDefaults,
})
export class ConsignaCustomerState {
  constructor(
    private ConsignaCustomerService: CustomerConsignaService,
    ) {}

  @Action(GetMuebleConsigna)
  getMuebleConsigna(ctx: StateContext<ConsignaCustomerStateModel>) {
    return this.ConsignaCustomerService.getMuebleConsigna().pipe(
      tap((result: any) => {
        ctx.patchState({
          muebleConsigna: result,
        });
      }),
      catchError( (err: any ) => {
        ctx.patchState({
          muebleConsigna: new Error(err),
        });
        return throwError(err);
      }));
  }

  @Action(GetConsignaData)
  getConsignaData(ctx: StateContext<ConsignaCustomerStateModel>) {
    return this.ConsignaCustomerService.getCosignaData().pipe(
      tap((result: any) => {
        ctx.patchState({
          consignaData: result,
        });
      }),
      catchError( (err: any ) => {
        ctx.patchState({
          consignaData: new Error(err),
        });
        return throwError(err);
      }));
  }

  @Action(ClientToLocker)
  clientToLocker(ctx: StateContext<ConsignaCustomerStateModel>, { data }) {
    return this.ConsignaCustomerService.clientToLocker( data ).pipe(
      tap((result: any) => {
        ctx.patchState({
          clientToLockerData: result,
        });
      }),
      catchError( (err: any ) => {
        ctx.patchState({
          clientToLockerData: new Error(err),
        });
        return throwError(err);
      }));
  }


  @Action(DropoffConfirmation)
  dropoffConfirmation(ctx: StateContext<ConsignaCustomerStateModel>, { data }) {
    return this.ConsignaCustomerService.dropoffConfirmation( data ).pipe(
      tap((result: any) => {
        ctx.patchState({
          dropoffConfirmationData: result,
        });
      }),
      catchError( (err: any ) => {
        ctx.patchState({
          dropoffConfirmationData: new Error(err),
        });
        return throwError(err);
      }));
  }


  @Action(PickupFromLocker)
  pickupFromLocker(ctx: StateContext<ConsignaCustomerStateModel>, { data }) {
    return this.ConsignaCustomerService.pickupFromLocker( data ).pipe(
      tap((result: any) => {
        ctx.patchState({
          pickupFromLockerData: result,
        });
      }),
      catchError( (err: any ) => {
        ctx.patchState({
          pickupFromLockerData: new Error(err),
        });
        return throwError(err);
      }));
  }
}
