import { Action, State, StateContext } from '@ngxs/store';
import { HideHelpCCConfirmation, ShowHelpCCConfirmation } from './helpCC.actions';

export class HelpCCStateModel {
  modalData: {
    hidden: boolean;
    area: string;
  };
}

export const HelpCCStateDefaults: HelpCCStateModel = {
  modalData: {
    hidden: true,
    area: ''

  }
};

@State<HelpCCStateModel>({
  name: 'helpccdropoff',
  defaults: HelpCCStateDefaults,
})
export class HelpCCState {
  @Action(ShowHelpCCConfirmation)
  show(ctx: StateContext<HelpCCStateModel>, {payload}: any) {
    ctx.patchState({
      modalData: {
        hidden: false,
        area: payload.area
      }
    });
  }

  @Action(HideHelpCCConfirmation)
  hide(ctx: StateContext<HelpCCStateModel>) {
    ctx.patchState({
      modalData: {
      hidden: true,
      area: ''
      }
    });
  }

}


