export class ShowConsignaDropoffConfirmation {
  static readonly type = '[CONFIRMATIONDROPOFF] Show retire';

  constructor (public payload: any) {
    //
  }
}

export class HideConsignaDropoffConfirmation {
  static readonly type = '[CONFIRMATIONDROPOFF] Hide';
}

