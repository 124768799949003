// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  /*   apiUrl: 'http://localhost:3000/api/v1',
  publicAssetsUrl: 'http://localhost:3000', */
  apiUrl: 'https://mqttcloud.lockers.hmy-retailtech.com/api/v1',
  platformUrl: 'https://platformlock.lockers.hmy-retailtech.com/api/v1',
  publicAssetsUrl: 'https://vlocker.lockers.hmy-retailtech.com',
  socketURL: 'https://realtime.lockers.hmy-retailtech.com',
  DEFAULT_TIMER: 45 * 1000,
  version: 'v1.0.1',
  showQR: false,
  staffStorageTokenKey: { cc: 'token-cc', consigna: 'token-consigna' },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
