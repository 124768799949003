import { LogInDto } from '../classes/log-in.dto';

export class LogInConsigna {
  static readonly type = '[STAFF] LogInConsigna';
  constructor(public payload: LogInDto) {}
}

export class LogOut {
  static readonly type = '[STAFF] LogOut';
  constructor(public showScreenSaver: boolean = false) {}
}

export class SyncConsignaLocker {
  static readonly type = '[STAFF] SyncConsignaLocker';
}

export class LoginById {
  static readonly type = '[CONSIGNASTAFF] LoginById';
  constructor(public id: string ) {}
}
