import { HttpErrorResponse } from '@angular/common/http';
import { Navigate } from '@ngxs/router-plugin';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ShowAlert } from '../../../../common/components/alert/store/alert.actions';
import { AuthToken } from '../../../../common/model/auth-token.model';
import { StaffService } from '../staff.service';
import { LogOut, SyncCCLocker, LogInCC, ScreenSaverOn, ScreenSaverOff } from './authCC.actions';
import { environment } from '../../../../../environments/environment.prod';
import { ScreenSaverService } from '../../../../common/services/screenSaver.service';

export class CCAuthStateModel {
  authToken: AuthToken;
  lockerCC: any;
}

export const CCAuthStateDefaults: CCAuthStateModel = {
  authToken: null,
  lockerCC: null
};

@State<CCAuthStateModel>({
  name: 'ccAuthStaff',
  defaults: CCAuthStateDefaults,
})
export class CCAuthState {
  constructor(
    private store: Store,
    private staffService: StaffService,
    private ssService: ScreenSaverService
    ) {}

  @Selector()
  static authToken(state: CCAuthStateModel) {
    return state.authToken;
  }

  setTokenKey(result, payload) {
    localStorage.setItem('lockerid', payload.lockerId);
    if (payload.rememberSession) {
      localStorage.setItem('remember_session', payload.rememberSession);
    }
    localStorage.setItem('role', result.role);
    if (result.role === 'staffAll' || result.role === 'staffCC') {
      localStorage.setItem( environment.staffStorageTokenKey.cc, JSON.stringify(result));
    }
    if (result.role === 'staffAll' || result.role === 'staffConsigment') {
      localStorage.setItem( environment.staffStorageTokenKey.consigna, JSON.stringify(result));
    }
  }

  @Action(LogInCC)
  logInCC(ctx: StateContext<CCAuthStateModel>, { payload }) {
    return this.staffService.login(payload).pipe(
      tap((result: any) => {
        this.setTokenKey(result, payload);
        ctx.patchState({
          authToken: result,
        });
        this.ssService.staffLoggedIn$.next(true);
        if (result.role === 'staffCC' || result.role === 'staffAll') {
          ctx.dispatch(new Navigate(['cc', 'staff', 'staff-menu']));
        } else if (result.role === 'staffConsigment') {
          ctx.dispatch(new Navigate(['consigna', 'staff', 'staff-menu']));
        }
      }),
      // tslint:disable-next-line:no-identical-functions
      catchError((err: HttpErrorResponse) => {
        console.log('LOGIN_ERROR -> ', err);
        if ( err && err.error && err.error.message === 'INVALID_EMAIL') {
          ctx.dispatch(new ShowAlert({ type: 'error', text: 'MESSAGES.STAFF_USER_INCORRECT' }));
        }
        if ( err && err.error && err.error.message === 'INVALID_PASSWORD') {
          ctx.dispatch(new ShowAlert({ type: 'error', text: 'MESSAGES.STAFF_PASSWORD_INCORRECT' }));
        }
        return throwError(err);
      }),
    );
  }

  @Action(LogOut)
  logOut(ctx: StateContext<CCAuthStateModel>, {showScreenSaver}) {
    localStorage.removeItem('token');
    localStorage.removeItem('remember_session');
    localStorage.removeItem('screensaver');
    localStorage.removeItem('tokenval');
    localStorage.removeItem('tokendate');
    localStorage.removeItem('tokenid');
    localStorage.removeItem(environment.staffStorageTokenKey.cc);
    localStorage.removeItem(environment.staffStorageTokenKey.consigna);
    ctx.patchState({
      authToken: null,
    });
    this.store.dispatch(new Navigate(['screensaver']));
  }

  @Action(ScreenSaverOn)
  setScreenSaverOn(ctx: StateContext<CCAuthStateModel>) {
    const IsOnScreenSaver = localStorage.getItem('IsOnScreenSaver');
    if (IsOnScreenSaver !== 'true') {
      return this.staffService.setOnScreenSaverConfig(true);
    }
    return true;
  }

  @Action(ScreenSaverOff)
  setScreenSaverOff(ctx: StateContext<CCAuthStateModel>) {
    const IsOnScreenSaver = localStorage.getItem('IsOnScreenSaver');
    if (IsOnScreenSaver !== 'false') {
      return this.staffService.setOnScreenSaverConfig(false);
    }
    return true;
  }

  @Action(SyncCCLocker)
    syncCCLocker(ctx: StateContext<CCAuthStateModel>) {
      return this.staffService.getCCData().pipe(
        tap(res => {
          ctx.patchState({
            lockerCC: res,
          });
        }),
        // tslint:disable-next-line: no-identical-functions
        catchError(err => {
          ctx.patchState({
            lockerCC: null
          });
          return throwError(err);
        }),
      );
    }

}
