import { NgModule } from '@angular/core';
import { AssetToFullUrl } from './assetToFullUrl.pipe';
import { SafeHtmlPipe } from './safeHtml.pipe';
import { CustomDatePipe } from './dateTransform.pipe';
import { FilterContactDataPipe } from './filterContactData.pipe';
@NgModule({
  declarations: [AssetToFullUrl, SafeHtmlPipe, CustomDatePipe, FilterContactDataPipe],
  exports: [AssetToFullUrl, SafeHtmlPipe, CustomDatePipe, FilterContactDataPipe],
})
export class PipesModule {}
