export class ClientToLockersDto {
    lang: string;
    phone?;
    email?;
    sizes: LockSize[];
    date;
    lockerId: string;
}

interface LockSize {
    name: string;
    quantity: number;
}
