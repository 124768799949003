import { Action, State, StateContext } from '@ngxs/store';
import { RetireLockers, OpenLockers, GetCCData, MakeDeliveryToBox, MakeDeliveryToLocker, BoxToCustomer, StaffLogin, RetireExpiredOrder, OpenLockersByStaff, OpenLockersByCoords, InsertFakeData, GetConfig, MakeDeliveryToLockerFirstStep, DisableLock, FromLockersToLocker0 } from './ccStaff.actions';
import { StaffService } from '../staff.service';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

export class CCStaffStateModel {
  verifyCodeData: any;
  retireLockersData: any;
  openLockersData: any;
  GetCCData: any;
  makeDeliveryToBoxData: any;
  makeDeliveryToLockerFirstStepData: any;
  makeDeliveryToLockerData: any;
  boxToCustomerData: any;
  staffLoginData: any;
  retireExpiredOrderData: any;
  openLockersByStaffData: any;
  openLockersByCoordsData: any;
  disableLockData: any;
  insertFakeData: any;
  getConfigData: any;
  fromLockersToLocker0Data: any;
}
export const CCStaffStateDefaults: CCStaffStateModel = {
  verifyCodeData: null,
  retireLockersData: null,
  openLockersData: null,
  GetCCData: null,
  makeDeliveryToBoxData: null,
  makeDeliveryToLockerFirstStepData: null,
  makeDeliveryToLockerData: null,
  boxToCustomerData: null,
  staffLoginData: null,
  retireExpiredOrderData: null,
  openLockersByStaffData: null,
  openLockersByCoordsData: null,
  disableLockData: null,
  insertFakeData: null,
  getConfigData: null,
  fromLockersToLocker0Data: null
};

@State<CCStaffStateModel>({
  name: 'ccStaff',
  defaults: CCStaffStateDefaults,
})
export class CCStaffState {
  constructor(
    private StaffService: StaffService,
    ) {}

  /* @Action(LogInCC)
  verifyCode(ctx: StateContext<CCStaffStateModel>, {loginDto}) {
    return this.StaffService.login(loginDto).pipe(
      tap((result: any) => {
        ctx.patchState({
          verifyCodeData: result,
        });
      }),
      catchError( (err: any ) => {
        ctx.patchState({
          verifyCodeData: new Error(err),
        });
        return throwError(err);
      }));
  } */

  @Action(RetireLockers)
  retireLockers(ctx: StateContext<CCStaffStateModel>, {email, code, reserve_date, idLockers}) {
    return this.StaffService.retireLockers(email, code, reserve_date, idLockers).pipe(
      tap((result: any) => {
        ctx.patchState({
          retireLockersData: result,
        });
      }),
      catchError( (err: any ) => {
        ctx.patchState({
          retireLockersData: new Error(err),
        });
        return throwError(err);
      }));
  }

  @Action(OpenLockers)
  openLockers(ctx: StateContext<CCStaffStateModel>, {idLockers}) {
    return this.StaffService.openLockers(idLockers).pipe(
      tap((result: any) => {
        ctx.patchState({
          openLockersData: result,
        });
      }),
      catchError( (err: any ) => {
        ctx.patchState({
          openLockersData: new Error(err),
        });
        return throwError(err);
      }));
  }

  @Action(GetCCData)
  pickupOrderLogin(ctx: StateContext<CCStaffStateModel>) {
    return this.StaffService.getCCData().pipe(
      tap((result: any) => {
        ctx.patchState({
          GetCCData: result,
        });
      }),
      catchError( (err: any ) => {
        ctx.patchState({
          GetCCData: new Error(err),
        });
        return throwError(err);
      }));
  }

  @Action(MakeDeliveryToBox)
  makeDeliveryToBox(ctx: StateContext<CCStaffStateModel>, {order}) {
    return this.StaffService.makeDeliveryToBox(order).pipe(
      tap((result: any) => {
        ctx.patchState({
          makeDeliveryToBoxData: result,
        });
      }),
      catchError( (err: any ) => {
        ctx.patchState({
          makeDeliveryToBoxData: new Error(err),
        });
        return throwError(err);
      }));
  }

  @Action(MakeDeliveryToLocker)
  makeDeliveryToLocker(ctx: StateContext<CCStaffStateModel>, {code}) {
    return this.StaffService.makeDeliveryToLocker(code).pipe(
      tap((result: any) => {
        ctx.patchState({
          makeDeliveryToLockerData: result,
        });
      }),
      catchError( (err: any ) => {
        ctx.patchState({
          makeDeliveryToLockerData: new Error(err),
        });
        return throwError(err);
      }));
  }

  @Action(MakeDeliveryToLockerFirstStep)
  makeDeliveryToLockerFirstStep(ctx: StateContext<CCStaffStateModel>, {code}) {
    return this.StaffService.makeDeliveryToLockerFirstStep(code).pipe(
      tap((result: any) => {
        ctx.patchState({
          makeDeliveryToLockerFirstStepData: result,
        });
      }),
      catchError( (err: any ) => {
        ctx.patchState({
          makeDeliveryToLockerFirstStepData: new Error(err),
        });
        return throwError(err);
      }));
  }

  @Action(BoxToCustomer)
  boxToCustomer(ctx: StateContext<CCStaffStateModel>, {orderId}) {
    return this.StaffService.boxToCustomer(orderId).pipe(
      tap((result: any) => {
        ctx.patchState({
          boxToCustomerData: result,
        });
      }),
      catchError( (err: any ) => {
        ctx.patchState({
          boxToCustomerData: new Error(err),
        });
        return throwError(err);
      }));
  }

  @Action(StaffLogin)
  staffLogin(ctx: StateContext<CCStaffStateModel>, {staffMember}) {
    return this.StaffService.staffLogin(staffMember).pipe(
      tap((result: any) => {
        ctx.patchState({
          staffLoginData: result,
        });
      }),
      catchError( (err: any ) => {
        ctx.patchState({
          staffLoginData: new Error(err),
        });
        return throwError(err);
      }));
  }

  @Action(RetireExpiredOrder)
  retireExpiredOrder(ctx: StateContext<CCStaffStateModel>, {dto}) {
    return this.StaffService.retireExpiredOrder(dto).pipe(
      tap((result: any) => {
        ctx.patchState({
          retireExpiredOrderData: result,
        });
      }),
      catchError( (err: any ) => {
        ctx.patchState({
          retireExpiredOrderData: new Error(err),
        });
        return throwError(err);
      }));
  }

  @Action(OpenLockersByStaff)
  openLockersByStaff(ctx: StateContext<CCStaffStateModel>, {orderId}) {
    return this.StaffService.openLockersByStaff(orderId).pipe(
      tap((result: any) => {
        ctx.patchState({
          openLockersByStaffData: result,
        });
      }),
      catchError( (err: any ) => {
        ctx.patchState({
          openLockersByStaffData: new Error(err),
        });
        return throwError(err);
      }));
  }

  @Action(OpenLockersByCoords)
  openLockersByCoords(ctx: StateContext<CCStaffStateModel>, {x, y}) {
    return this.StaffService.openLockersByCoords( x, y).pipe(
      tap((result: any) => {
        ctx.patchState({
          openLockersByCoordsData: result,
        });
      }),
      catchError( (err: any ) => {
        ctx.patchState({
          openLockersByCoordsData: new Error(err),
        });
        return throwError(err);
      }));
  }

  @Action(InsertFakeData)
  insertFakeData(ctx: StateContext<CCStaffStateModel>) {
    return this.StaffService.insertFakeData().pipe(
      tap((result: any) => {
        ctx.patchState({
          insertFakeData: result,
        });
      }),
      catchError( (err: any ) => {
        ctx.patchState({
          insertFakeData: new Error(err),
        });
        return throwError(err);
      }));
  }

  @Action(GetConfig)
  getConfig(ctx: StateContext<CCStaffStateModel>) {
    return this.StaffService.getConfig().pipe(
      tap((result: any) => {
        ctx.patchState({
          getConfigData: result,
        });
      }),
      catchError( (err: any ) => {
        ctx.patchState({
          getConfigData: new Error(err),
        });
        return throwError(err);
      }));
  }

  @Action(DisableLock)
  disableLock(ctx: StateContext<CCStaffStateModel>, {lockerId, disable}) {
    return this.StaffService.disableLock(lockerId, disable).pipe(
      tap((result: any) => {
        ctx.patchState({
          disableLockData: result,
        });
      }),
      catchError( (err: any ) => {
        ctx.patchState({
          disableLockData: new Error(err),
        });
        return throwError(err);
      }));
  }

  @Action(FromLockersToLocker0)
  fromLockersToLocker0(ctx: StateContext<CCStaffStateModel>, {dto}) {
    return this.StaffService.fromLockersToLocker0(dto).pipe(
      tap((result: any) => {
        ctx.patchState({
          fromLockersToLocker0Data: result,
        });
      }),
      catchError( (err: any ) => {
        ctx.patchState({
          fromLockersToLocker0Data: new Error(err),
        });
        return throwError(err);
      }));
  }

}
