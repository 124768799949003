export class ShowHelpCCConfirmation {
  static readonly type = '[HELPCCDROPOFF] Show retire';

  constructor (public payload: any) {
    //
  }
}

export class HideHelpCCConfirmation {
  static readonly type = '[HELPCCDROPOFF] Hide';
}

